@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);
  $shadow: map.get($background, nm-button-shadow);
  .onboarding-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    .header {
      height: 64px;
      img {
        height: 64px;
      }
    }
  }

  .create-organization {
    width: 552px;

    nm-svg-icon {
      color: mat.get-color-from-palette($foreground, create-organization-icon);
    }

    .title {
      font-size: mat.font-size($typography, nm-create-organization-title);
      font-weight: mat.font-weight($typography, nm-create-organization-title);
      line-height: mat.line-height($typography, nm-create-organization-title);
      color: mat.get-color-from-palette($foreground, create-organization-title);
      text-wrap: balance;
      margin-bottom: 0;
    }

    .description {
      margin-top: 12px;
      font-size: mat.font-size($typography, nm-create-organization-description);
      font-weight: mat.font-weight($typography, nm-create-organization-description);
      line-height: mat.line-height($typography, nm-create-organization-description);
      letter-spacing: mat.letter-spacing($typography, nm-create-organization-description);
      color: mat.get-color-from-palette($foreground, create-organization-description);
    }

    .actions {
      .organization-row {
        margin-top: 24px;

        &.button {
          width: 103px;
        }
      }
    }
    .new-organization {
      display: flex;
      gap: 7px;
    }

    .new-organization-label {
      margin-top: 5px;
      display: flex;
      flex-direction: column;
      .label-text {
        color: mat.get-color-from-palette($foreground, nm-control-label);
        font-size: 14px;
        font-weight: mat.font-weight($typography, nm-control-label);
        letter-spacing: mat.letter-spacing($typography, nm-control-label);
        line-height: mat.line-height($typography, nm-control-label);
      }
      .checkbox-hint {
        font-size: mat.font-size($typography, nm-control-hint);
        font-weight: mat.font-weight($typography, nm-control-hint);
        letter-spacing: mat.letter-spacing($typography, nm-control-hint);
        line-height: mat.line-height($typography, nm-control-hint);
        color: mat.get-color-from-palette($foreground, nm-hint-helper);
      }
    }
    .organization-types {
      margin-top: 20px;
      .type-divider {
        display: block;
        padding: 8px 0 7px;
      }
    }
  }
  .mat-step-label {
    width: 100%;
    height: 100%;
    background-color: mat.get-color-from-palette($foreground, nm-table-divider);
  }
  .mat-step-label-active {
    cursor: pointer;
    background-color: mat.get-color-from-palette($background, nm-filled-button);
  }
  .mat-horizontal-stepper-header {
    padding: 0 !important;
    height: 4px !important;
  }
  .mat-horizontal-content-container {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .mat-horizontal-stepper-header-container {
    justify-content: space-between;
    gap: 7px;
    padding: 0 32px;
  }
  .mat-step-header {
    flex: 1;

    .mat-step-icon {
      display: none;
    }
  }

  .mat-stepper-horizontal-line {
    display: none;
  }

  .onboarding-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 64px;
    width: 100%;
    position: fixed;
    bottom: 0;

    .btn-group {
      display: flex;
      justify-content: space-between;
      width: 552px;
    }
    .right-buttons {
      display: flex;
      gap: 16px;
    }
    nm-divider {
      width: 100%;
      margin-bottom: 15px;
    }
    .tabulation-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: mat.get-color-from-palette($background, nm-filled-button);
      &:disabled {
        background-color: mat.get-color-from-palette($background, nm-button-disabled-12);
        color: mat.get-color-from-palette($foreground, nm-button-disabled-38);
      }
      &:hover:disabled {
        cursor: default;
      }
      &:hover:not(:disabled) {
        box-shadow: $shadow;

        r .mat-button-focus-overlay {
          opacity: 0.08;
        }
      }

      &:active,
      &.pressed:not(:disabled) {
        box-shadow: none;
      }
    }
    .outlined {
      background-color: unset;
      $border-color: mat.get-color-from-palette($background, nm-outlined-button-border);
      border: 1px solid $border-color;
      &:disabled {
        border-color: mat.get-color-from-palette($background, nm-button-disabled-12);
        color: mat.get-color-from-palette($foreground, nm-button-disabled-38);
        background-color: unset;
      }
    }
    .mat-progress-spinner circle,
    .mat-spinner circle {
      stroke: mat.get-color-from-palette($foreground, nm-avatat-icon-color);;
    }
  }
  .checkbox-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 0 12px;
  }
  .checkbox-label {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    > nm-svg-icon {
      width: 20px;
      height: 20px;
    }
  }

  @media screen and (max-width: 600px) {
    .create-organization {
      width: 280px;
    }
    .mat-horizontal-stepper-header-container {
      padding: 0 16px !important;
    }
    .btn-group {
      width: 280px !important;
    }
  }
  @media screen and (max-width: 900px) and (min-width: 600px) {
    .mat-horizontal-stepper-header-container {
      padding: 0 24px !important;
    }
  }
}
