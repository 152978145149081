@use '@angular/material' as mat;
@use 'sass:map';

:host {
  display: inline-flex;
  overflow: hidden;
}

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  $nm-tag: mat.get-color-from-palette($background, nm-tag);
  $nm-tag-success: mat.get-color-from-palette($background, nm-tag-success);
  $nm-tag-warning: mat.get-color-from-palette($background, nm-tag-warning);
  $nm-tag-true-mark: mat.get-color-from-palette($background, nm-tag-true-mark);
  $nm-tag-true-mark-text: mat.get-color-from-palette($background, nm-tag-true-mark-text);
  $nm-tag-text: mat.get-color-from-palette($background, nm-tag-text);

  .nm-tag {
    min-height: 24px;
    padding: 2px 12px;
    border-radius: 12px;

    display: flex;
    column-gap: 4px;
    align-items: center;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    background-color: $nm-tag;
    color: $nm-tag-text;
    font-size: mat.font-size($typography, text-sm-500);
    font-weight: mat.font-weight($typography, text-sm-500);
    letter-spacing: mat.letter-spacing($typography, text-sm-500);
    line-height: mat.line-height($typography, text-sm-500);

    &.nm-tag-success {
      background-color: $nm-tag-success;
    }

    &.nm-tag-warning {
      background-color: $nm-tag-warning;
    }

    &.nm-tag-true-mark {
      background-color: $nm-tag-true-mark;
      color: $nm-tag-true-mark-text;
    }
  }
}
