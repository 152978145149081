@use '@angular/material' as mat;
@use 'sass:map';

@import 'typography';

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $typography: mat.get-typography-config($theme);

  $nm-alert-banner-information: mat.get-color-from-palette($foreground, nm-alert-banner-information);
  $nm-alert-banner-information-color: mat.get-color-from-palette($foreground, nm-alert-banner-information-color);
  $nm-alert-banner-success: mat.get-color-from-palette($foreground, nm-alert-banner-success);
  $nm-alert-banner-success-color: mat.get-color-from-palette($foreground, nm-alert-banner-success-color);
  $nm-alert-banner-warning: mat.get-color-from-palette($foreground, nm-alert-banner-warning);
  $nm-alert-banner-warning-color: mat.get-color-from-palette($foreground, nm-alert-banner-warning-color);
  $nm-alert-banner-error: mat.get-color-from-palette($foreground, nm-alert-banner-error);
  $nm-alert-banner-error-color: mat.get-color-from-palette($foreground, nm-alert-banner-error-color);

  .alert-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 20px;
    min-height: 48px;
    line-height: 0;

    &.information {
      background-color: $nm-alert-banner-information;

      .title,
      .description,
      .link {
        color: $nm-alert-banner-information-color;
      }
    }

    &.success {
      background-color: $nm-alert-banner-success;

      .title,
      .description,
      .link {
        color: $nm-alert-banner-success-color;
      }
    }

    &.warning {
      background-color: $nm-alert-banner-warning;

      .title,
      .description,
      .link {
        color: $nm-alert-banner-warning-color;
      }
    }

    &.error {
      background-color: $nm-alert-banner-error;

      .title,
      .description,
      .link {
        color: $nm-alert-banner-error-color;
      }
    }

    .title,
    .link {
      display: inline;
      font-size: mat.font-size($typography, text-2xs-500);
      font-weight: mat.font-weight($typography, text-2xs-500);
      letter-spacing: mat.letter-spacing($typography, text-2xs-500);
      line-height: mat.line-height($typography, text-2xs-500);
    }

    .link {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
