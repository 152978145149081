@use '@angular/material' as mat;
@use 'sass:map';

.order-state {
  .order {
    .order-state__table {
      ::ng-deep {
        .table-wrapper {
          .inner {
            .column {
              &:first-child {
                padding-left: 0 !important;
              }

              &:last-child {
                padding-right: 0 !important;
              }
            }
          }
        }
      }
    }
  }
}

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $typography: mat.get-typography-config($theme);

  $nm-order-state-title: mat.get-color-from-palette($foreground, nm-order-state-title);
  $nm-order-state-subtitle: mat.get-color-from-palette($foreground, nm-order-state-subtitle);
  $nm-order-state-description: mat.get-color-from-palette($foreground, nm-order-state-description);

  .order-state {
    display: flex;
    justify-content: center;
    max-width: 556px;
    margin: 0 auto;

    .order {
      display: flex;
      flex-direction: column;
      width: 100%;

      .header {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        margin-bottom: 16px;

        .title {
          color: $nm-order-state-title;
          font-size: mat.font-size($typography, display-xs-600);
          font-weight: mat.font-weight($typography, display-xs-600);
          letter-spacing: mat.letter-spacing($typography, display-xs-600);
          line-height: mat.line-height($typography, display-xs-600);
        }

        .subtitle {
          color: $nm-order-state-subtitle;
          font-size: mat.font-size($typography, text-xs-400);
          font-weight: mat.font-weight($typography, text-xs-400);
          letter-spacing: mat.letter-spacing($typography, text-xs-400);
          line-height: mat.line-height($typography, text-xs-400);
        }
      }

      .order-state__table {
        margin-bottom: 40px;

        .container {
          display: flex;
          flex-direction: column;
        }
      }

      .actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .success,
    .fail {
      display: flex;
      flex-direction: column;
      row-gap: 24px;

      .container {
        display: flex;
        flex-direction: column;
        row-gap: 8px;

        .title {
          color: $nm-order-state-title;
          font-size: mat.font-size($typography, display-xs-600);
          font-weight: mat.font-weight($typography, display-xs-600);
          letter-spacing: mat.letter-spacing($typography, display-xs-600);
          line-height: mat.line-height($typography, display-xs-600);
        }

        .description {
          color: $nm-order-state-description;
          font-size: mat.font-size($typography, text-sm-400);
          font-weight: mat.font-weight($typography, text-sm-400);
          letter-spacing: mat.letter-spacing($typography, text-sm-400);
          line-height: mat.line-height($typography, text-sm-400);
        }
      }
    }
  }
}
