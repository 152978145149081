@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $typography: mat.get-typography-config($theme);

  nm-input-search{
    width: 100%;
  }

  .nm-input-search {
    height: 32px;
    width: 100%;
    border-radius: 8px;
    color: mat.get-color-from-palette($foreground, nm-input-search-color);

    padding: 7px 8px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    user-select: none;
    gap: 8px;
    align-items: center;

    &.is-top-bar {
      padding: 7px 0;
    }

    input {
        width: 100%;
        border: none;
        outline: none;
        background: transparent;
        font-size: mat.font-size($typography, nm-input-search);
        font-weight: mat.font-weight($typography, nm-input-search);
        line-height: mat.line-height($typography, nm-input-search);
        color: mat.get-color-from-palette($foreground, nm-input-search-color);
    }
  }
}
