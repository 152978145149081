@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);
  $typography: mat.get-typography-config($theme);
  .drag-preview-container {
    width: 200px;
    height: 200px;
  }

  .border-left {
    border-left: 1px solid mat.get-color-from-palette($foreground, nm-table-header-border);
  }

  .border-right {
    border-right: 1px solid mat.get-color-from-palette($foreground, nm-table-header-border);
  }

  .border-left {
    border-left: 1px solid mat.get-color-from-palette($foreground, nm-table-header-border);
  }

  .border-right {
    border-right: 1px solid mat.get-color-from-palette($foreground, nm-table-header-border);
  }

  .drag-preview {
    width: 156px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    isolation: isolate;
    background: mat.get-color-from-palette($foreground, nm-table-drag-preview);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15) !important;
    overflow: visible !important;
    border-radius: 8px;
    font-size: mat.font-size($typography, nm-table-drag-preview);
    font-weight: mat.font-weight($typography, nm-table-drag-preview);
    line-height: mat.line-height($typography, nm-table-drag-preview);
    letter-spacing: mat.letter-spacing($typography, nm-table-drag-preview);
  }

  .color {
    background-color: mat.get-color-from-palette($foreground, nm-table-custom-color);
  }

  .row {
    display: flex;
    &:not(.mobile-row):not(.drag-mode) {
      &:hover {
        background: mat.get-color-from-palette($foreground, nm-table-row-hover);
        .column.pinned {
          background: mat.get-color-from-palette($foreground, nm-table-row-hover);
        }
      }
    }

    &.highlighted {
      background: mat.get-color-from-palette($foreground, nm-table-highlighted-row);

      &:hover {
        background: mat.get-color-from-palette($foreground, nm-table-highlighted-row-hover);
      }

      .column {
        font-size: mat.font-size($typography, nm-table-highlighted-cell);
        font-weight: mat.font-weight($typography, nm-table-highlighted-cell);
        line-height: mat.line-height($typography, nm-table-highlighted-cell);
        letter-spacing: mat.letter-spacing($typography, nm-table-highlighted-cell);

        &.hover {
          background: mat.get-color-from-palette($foreground, nm-table-header-hover);
        }

        &.hidden-column {
          display: none;
        }

        &.pinned {
          z-index: 1;
          position: sticky;
          left: 0;
          width: 100%;
          background: mat.get-color-from-palette($foreground, nm-table-pinned-bg);
          border-right: 1px solid mat.get-color-from-palette($foreground, nm-table-pinned-border);
        }

        .column-content {
          font-size: mat.font-size($typography, nm-table-highlighted-cell);
          font-weight: mat.font-weight($typography, nm-table-highlighted-cell);
          line-height: mat.line-height($typography, nm-table-highlighted-cell);
          letter-spacing: mat.letter-spacing($typography, nm-table-highlighted-cell);
        }
      }
    }
    &.drag-hovered {
      background: mat.get-color-from-palette($foreground, nm-table-highlighted-on-dragover);
    }
    &.add-to-top:not(.drag-hovered) {
      .up {
        display: block;
      }
      .down {
        display: none;
      }
    }
    &.add-to-bottom:not(.drag-hovered) {
      .up {
        display: none;
      }
      .down {
        display: block;
      }
    }
    .pointer {
      position: absolute;
      width: 100%;
      height: 3px;
      background-color: mat.get-color-from-palette($foreground, nm-table-drop-placeholder);
    }
    .up {
      top: -3px;
      display: none;
    }
    .down {
      bottom: 0;
      display: none;
    }

    .drop-inside {
      position: absolute;
      width: 100%;
      height: 50%;
      top: 25%;
      bottom: 15px;
      z-index: 0;
    }
    &.row-dragover {
      .drop-inside {
        z-index: 20;
      }
    }
    .row-enumerator {
      width: 34px;
      height: 20px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-right: 4px;

      font-size: mat.font-size($typography, nm-table-cell);
      font-weight: mat.font-weight($typography, nm-table-cell);
      line-height: mat.line-height($typography, nm-table-cell);
      letter-spacing: mat.letter-spacing($typography, nm-table-cell);

      text-align: right;
      color: mat.get-color-from-palette($foreground, nm-table-enumerator);
    }

    .inner {
      display: grid;
      grid-auto-flow: column;
      width: 100%;
      align-items: center;
    }

    .drag-handler {
      display: none;
    }

    &.drag-mode {
      .drag-handler {
        &:hover {
          cursor: grabbing;
        }
      }

      .column {
        .column-expand {
          cursor: grabbing;
        }
      }
    }

    &.selected:hover {
      .column.pinned {
        background: mat.get-color-from-palette($foreground, nm-table-hover-selected-bg) !important;
      }
      .inner .column {
        background: mat.get-color-from-palette($foreground, nm-table-hover-selected-bg);
      }
    }

    &.selected {
      .inner .column {
        background: mat.get-color-from-palette($foreground, nm-table-selected-bg-withoutop);
      }

      .column.column-numeric {
        &:hover {
          .column-content {
            background-color: mat.get-color-from-palette($foreground, nm-table-hover-selected-bg);
          }
        }
      }
    }

    &:hover {
      .drag-handler {
        display: block;
        position: absolute;
        left: -7px;
        top: 50%;
        margin-top: -12px;
        cursor: grab;

        nm-drag-handler {
          nm-svg-icon {
            color: mat.get-color-from-palette($foreground, nm-table-drag-handler);
          }
        }
      }
    }
  }

  .column {
    display: flex;
    overflow: hidden;
    word-break: break-word;

    min-width: 64px;
    min-height: 36px;
    height: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    color: mat.get-color-from-palette($foreground, nm-table-column-text);
    font-size: mat.font-size($typography, nm-table-cell);
    font-weight: mat.font-weight($typography, nm-table-cell);
    line-height: mat.line-height($typography, nm-table-cell);
    letter-spacing: mat.letter-spacing($typography, nm-table-cell);
    padding: 8px 12px;
    border-bottom: 1px solid mat.get-color-from-palette($foreground, nm-table-divider);

    &.edit-column {
      padding: 1px;
      position: relative;
      overflow: visible;

      .column-content {
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }

    &.hidden-column {
      display: none;
    }

    .bar-column {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 5px;
      justify-self: flex-end;
      background: mat.get-color-from-palette($foreground, nm-table-header-bar);
      background-clip: content-box;
      opacity: 0;

      &.active {
        height: 100%;
        opacity: 1;
      }
    }

    &.pinned {
      z-index: 10;
      position: sticky;
      left: 0;
      background: mat.get-color-from-palette($foreground, nm-table-pinned-bg);
      border-right: 1px solid mat.get-color-from-palette($foreground, nm-table-pinned-border);
    }

    &.left {
      position: sticky;
      left: 0;
    }

    .column-expand {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 8px;
      min-width: 32px;
      cursor: pointer;
      z-index: 8;
      color: mat.get-color-from-palette($foreground, nm-table-column-arrow);

      &.hide-expand {
        display: none;
      }

      &:empty {
        cursor: default;
      }
    }

    .column-content {
      width: 100%;
      color: mat.get-color-from-palette($foreground, nm-table-column-text);
      font-size: mat.font-size($typography, nm-table-cell);
      font-weight: mat.font-weight($typography, nm-table-cell);
      line-height: mat.line-height($typography, nm-table-cell);
      letter-spacing: mat.letter-spacing($typography, nm-table-cell);
    }

    &.expand-column {
      .column-content {
        display: flex;
      }
    }

    &.grouped-row {
      .col-content {
        font-weight: mat.font-weight($typography, nm-table-grouped-cell);
      }
    }

    &.column-numeric {
      word-break: keep-all;
      justify-content: flex-start;

      &.column-numeric-right {
        justify-content: flex-end;
      }

      &:hover {
        overflow: visible;

        .column-content {
          width: max-content;
          background-color: mat.get-color-from-palette($background, nm-sidenav-layout-content);
          position: relative;
          z-index: 1;
          padding: 0 10px;
          transform: translateX(-10px);
        }

        &.column-numeric-right {
          .column-content {
            transform: translateX(10px);
          }
        }
      }

      .column-content {
        white-space: nowrap;
      }
    }

    &.sortable {
      cursor: pointer;
    }

    &.select-column {
      min-width: unset;
      padding-left: 0;
      padding-right: 0;

      .checkbox-wrapper {
        align-items: center;
        justify-content: center;

        width: 40px;
        height: 40px;
      }
    }
    &.number-column {
      min-width: unset;
      padding-left: 0;
      padding-right: 0;
    }
    .number-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: mat.get-color-from-palette($foreground, nm-table-enumerator);
      width: 40px;
      height: 40px;
    }
    &.auto-width {
      max-width: 214px;
    }

    &.transparent-border {
      border-color: transparent;
    }

    &.top-border {
      border-top: 1px solid mat.get-color-from-palette($foreground, nm-table-divider);
    }
  }

  .drag-placeholder {
    height: 1px;
    width: 100%;
    background: mat.get-color-from-palette($foreground, nm-table-drop-placeholder);
  }
}
