@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  .nm-metric {
    display: flex;
    flex-direction: column;
    justify-content: start;
    flex: 1 0 0;
    padding: 6px 0;
    overflow: hidden;

    .title-row {
      display: flex;
      flex-direction: row;
      gap: 4px;
      align-items: center;
      overflow: hidden;

      .title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: mat.font-size($typography, text-sm-500);
        font-weight: mat.font-weight($typography, text-sm-500);
        letter-spacing: mat.letter-spacing($typography, text-sm-500);
        line-height: mat.line-height($typography, text-sm-500);
        color: mat.get-color-from-palette($foreground, nm-metric-title);
      }

      .help-icon {
        min-width: 14px;
        color: mat.get-color-from-palette($foreground, nm-metric-help-icon);
      }
    }

    .value-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      overflow: hidden;

      .value {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: mat.font-size($typography, text-xl-600);
        font-weight: mat.font-weight($typography, text-xl-600);
        letter-spacing: mat.letter-spacing($typography, text-xl-600);
        line-height: mat.line-height($typography, text-xl-600);
        color: mat.get-color-from-palette($foreground, nm-metric-value);
      }

      .secondary-value-container {
        display: flex;
        flex-direction: row;
        gap: 2px;
        overflow: hidden;

        &.plain {
          color: mat.get-color-from-palette($foreground, nm-metric-secondary-value-plain);
        }

        &.error {
          color: mat.get-color-from-palette($foreground, nm-metric-secondary-value-error);
        }

        &.success {
          color: mat.get-color-from-palette($foreground, nm-metric-secondary-value-success);
        }

        .secondary-value-icon {
          min-width: 16px;
        }

        .secondary-value {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: mat.font-size($typography, text-xs-500);
          font-weight: mat.font-weight($typography, text-xs-500);
          letter-spacing: mat.letter-spacing($typography, text-xs-500);
          line-height: mat.line-height($typography, text-xs-500);
        }
      }
    }

    .description {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: mat.font-size($typography, text-xs-400);
      font-weight: mat.font-weight($typography, text-xs-400);
      letter-spacing: mat.letter-spacing($typography, text-xs-400);
      line-height: mat.line-height($typography, text-xs-400);
      color: mat.get-color-from-palette($foreground, nm-metric-description);
    }

    .compare {
      margin-left: 2px;
    }

    .skeleton-wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 4px;

      .title-loading {
        width: 88px;
        height: 20px;
      }

      .value-loading {
        width: 140px;
        height: 24px;
      }
    }
  }
}
