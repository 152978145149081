@use '@angular/material' as mat;
@use 'sass:map';
@import 'media-vars';

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);
  $typography: mat.get-typography-config($theme);

  .mat-dialog-container {
    display: flex;
    flex-direction: column;
    height: auto !important;
    overflow: hidden;
    min-width: 280px;
    width: auto;
    padding: 0 !important;
    border-radius: 28px !important;
    box-shadow: mat.get-color-from-palette($background, nm-modal-shadow) !important;

    .nm-dialog-container {
      display: flex;
      flex-direction: column;
      background-color: mat.get-color-from-palette($background, nm-modal);
      height: 100%;

      .modal-base-icon-container {
        display: flex;
        align-items: center;
        color: mat.get-color-from-palette($foreground, nm-modal-icon);
      }

      .modal-base-title-container {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        color: mat.get-color-from-palette($background, nm-modal-text);
        font-size: mat.font-size($typography, display-xs-600);
        font-weight: mat.font-weight($typography, display-xs-600);
        line-height: mat.line-height($typography, display-xs-600);
        text-align: left;

        &.icon-added {
          text-align: center;
        }
      }

      .header-center {
        .modal-base-icon-container, .modal-base-title-container {
          justify-content: center;
        }
      }
    }
  }

  .small-radius {
    .mat-dialog-container {
      border-radius: 4px !important;
    }
  }

  .modal-base-title-container {
    flex-grow: 0;
    flex-shrink: 1;
    padding: 24px 24px 0 24px;
  }

  .modal-base-footer {
    flex-grow: 0;
    flex-shrink: 1;
    padding: 0 24px 24px 24px;
    align-items: center;

    &.content-scroll {
      padding-top: 24px;
      border-top: solid 1px mat.get-color-from-palette($background, nm-modal-divider);
    }
  }

  .modal-base-title-container, .modal-base-footer {
    flex-basis: 90px;
    display: flex;
    align-items: flex-end;
  }

  .modal-base-content-container {
    flex: 1;
    -ms-flex: 1 1 auto;
    overflow-y: auto;
    padding: 0 24px 24px 24px;
    color: mat.get-color-from-palette($background, nm-modal-text);
    font-size: mat.font-size($typography, text-sm-400);
    font-weight: mat.font-weight($typography, text-sm-400);
    line-height: mat.line-height($typography, text-sm-400);
    text-align: left;
  }

  .btn {
    display: flex;
    width: 44px;
    height: 20px;
    align-items: center;
    color: mat.get-color-from-palette($background, nm-modal-btn);
    font-size: mat.font-size($typography, text-sm-400);
    font-weight: mat.font-weight($typography, text-sm-400);
    line-height: mat.line-height($typography, text-sm-400);
    text-align: center;
  }

  .btn-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;

    nm-button {
      margin-right: 8px;
    }

    nm-button:last-child {
      margin-right: 0;
    }

    .btn {
      margin-right: 32px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .modal-base-actions {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 8px;
  }

  @include media-mixin($media-xs) {
    .nm-dialog-container {
      width: 280px;
    }
  }

  @include media-mixin($media-s) {
    .nm-dialog-container {
      width: 320px;
    }
  }

  @include media-mixin($media-m) {
    .nm-dialog-container {
      width: 560px;
    }
  }
}
