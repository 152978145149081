@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $typography: mat.get-typography-config($theme);

  .nm-paginations {
    display: flex;
    align-items: center;
    column-gap: 4px;
    justify-content: center;
  }
}
