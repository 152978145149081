@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);

  .nm-breadcrumbs, .nm-breadcrumb {
    display: flex;
    align-items: center;
  }

  .nm-breadcrumbs {
    .arrow-icon {
      color: mat.get-color-from-palette($foreground, nm-breadcrumbs-arrow);
    }
  }
}
