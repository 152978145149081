@use '@angular/material' as mat;
@use 'sass:map';

:host {
  display: block;
  width: 100%;
}

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);

  .nm-form {
    > * {
      display: block;
      width: 100%;
      max-width: 552px;
      margin: 0 0 24px;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    nm-form-group {
      margin: 40px 0;
    }

    .collapsed {
      margin: 0 0 16px;
    }
  }
}
