@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  .nm-widget-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    gap: 8px;
    padding: 12px 16px;
    min-height: 64px;
    background-color: mat.get-color-from-palette($background, nm-widget-header-background);
    border-radius: 16px 16px 0 0;
    box-shadow: inset 0 -1px 0 0 mat.get-color-from-palette($foreground, nm-widget-header-border);

    .clickable {
      cursor: pointer;
    }

    .left {
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .label-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        width: 100%;
        font-size: mat.font-size($typography, text-md-600);
        font-weight: mat.font-weight($typography, text-md-600);
        letter-spacing: mat.letter-spacing($typography, text-md-600);
        line-height: mat.line-height($typography, text-md-600);
        color: mat.get-color-from-palette($foreground, nm-widget-header-label);

        .label-beginning {
          display: flex;
          flex-direction: row;
          overflow: hidden;

          .label {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .label-compare {
          margin-left: 7px;
        }
        .label-starting{
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-right: 4px;
        }
        .label-ending {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-left: 4px;
        }
      }

      .description {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: mat.font-size($typography, text-xs-400);
        font-weight: mat.font-weight($typography, text-xs-400);
        letter-spacing: mat.letter-spacing($typography, text-xs-400);
        line-height: mat.line-height($typography, text-xs-400);
        color: mat.get-color-from-palette($foreground, nm-widget-header-description);
      }
    }

    .right {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;

      .settings-icon {
        cursor: pointer;
      }
    }

    nm-svg-icon {
      color: mat.get-color-from-palette($foreground, nm-widget-header-icon);
      min-width: 24px;
    }

    .menu {
      display: none;
    }

    .skeleton {
      height: 24px;
      flex-grow: 1;
    }
  }
}
