@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  .nm-filter-radio {
    cursor: pointer;

    ::ng-deep {
      .text-wrapper {
        margin-right: 8px;
        user-select: none;
      }
    }
  }
}

