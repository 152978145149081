@use '@angular/material' as mat;
@use 'sass:map';
@use 'core' as core;
@import 'media-vars';

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $typography: mat.get-typography-config($theme);

  $nm-filters-table-text: mat.get-color-from-palette($foreground, nm-filters-table-text);

  .filters-table {
    &.hide-filters {
      nm-input-search, [filter], .settings {
        display: none;
      }

      [filter]:first-child, .settings:first-child {
        display: block;
      }

      .header, .content {
        gap: 0;
      }

      .control-panel {
        padding: 12px 16px 8px;
      }
    }

    .text {
      color: $nm-filters-table-text;
      font-size: mat.font-size($typography, text-sm-500);
      font-weight: mat.font-weight($typography, text-sm-500);
      letter-spacing: mat.letter-spacing($typography, text-sm-500);
      line-height: mat.line-height($typography, text-sm-500);
    }

    .filters {

      &.filters-icon {
        position: relative;
        top: -4px;
        height: 45px !important;
      }

      .icon {
        color: mat.get-color-from-palette($foreground, nm-filters-table-icon);
      }

      &.active-filter {
        color: mat.get-color-from-palette($foreground, nm-filters-table-active-icon);
        nm-svg-icon {
          color: mat.get-color-from-palette($foreground, nm-filters-table-active-icon) !important;
        }
      }
    }
  }
}

.filters-table {
  ::ng-deep {
    .header {
      align-items: center;
      justify-content: space-between;
    }

    .content {
      .left-side {
        position: relative;
        align-items: center;
      }

      .right-side {
        align-items: center;
      }
    }
  }

  .select {
    display: flex;
    align-items: center;
    column-gap: 8px;
    height: 32px;

    .text {
      width: 100px;
    }

    .actions, .cancel {
      display: flex;
      align-items: center;
      column-gap: 4px;
    }
  }

  @media screen and (max-width: 767px) {
    .select {
      height: 40px;
    }
  }

  .settings {
    display: flex;

    &.empty-filters {
      grid-column: 1 / 1;
    }
  }

  .menu {
    display: none;
  }

  .filters {
    position: relative;
    overflow: hidden;
    min-height: 40px;
    flex-wrap: wrap;
    align-items: center;
    padding: 4px 0;
    gap: 8px;
    transition: height 0.1s ease-in;
    will-change: height;

    &.one-row {
      height: 40px !important;
    }

    .chip {
      max-width: 200px;
    }
  }

  .fake {
    position: absolute;
    z-index: -1;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }

  .filters-list {
    display: flex;
    min-width: 0;
    gap: 8px;
  }

  @include media-mixin($media-max-xs) {
    .select-button {
      display: none;
    }
  }
}

::ng-deep .theme-light {
  @include theme(core.$theme-light);
}

::ng-deep .theme-dark {
  @include theme(core.$theme-dark);
}
