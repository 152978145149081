@use '@angular/material' as mat;
@use 'sass:map';

:host {
  display: flex;
  align-items: center;
}

@mixin nm-filled-button ($config) {
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);
  $foreground-color: mat.get-color-from-palette($foreground, nm-filled-button);

  background-color: mat.get-color-from-palette($background, nm-filled-button);
  color: $foreground-color;

  .mat-progress-spinner circle, .mat-spinner circle {
    color: $foreground-color;
    stroke: $foreground-color;
  }

  &.mat-button-disabled {
    background-color: mat.get-color-from-palette($background, nm-button-disabled-12);
    color: mat.get-color-from-palette($foreground, nm-button-disabled-38);
  }

  &:not(.mat-button-disabled) {
    .mat-button-focus-overlay {
      background-color: $foreground-color;
    }

    &:focus-visible {
      .mat-button-focus-overlay {
        opacity: 0.12;
      }
    }

    &:hover {
      .mat-button-focus-overlay {
        opacity: 0.08;
      }
    }

    &:active, &.pressed {
      .mat-button-focus-overlay {
        opacity: 0.12;
      }
    }
  }
}

@mixin nm-tonal-button ($config) {
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);
  $foreground-color: mat.get-color-from-palette($foreground, nm-tonal-button);

  background-color: mat.get-color-from-palette($background, nm-tonal-button);
  color: $foreground-color;

  .mat-progress-spinner circle, .mat-spinner circle {
    color: $foreground-color;
    stroke: $foreground-color;
  }

  &.mat-button-disabled {
    background-color: mat.get-color-from-palette($background, nm-button-disabled-12);
    color: mat.get-color-from-palette($foreground, nm-button-disabled-38);
  }

  &:not(.mat-button-disabled) {
    .mat-button-focus-overlay {
      background-color: $foreground-color;
    }

    &:focus-visible {
      .mat-button-focus-overlay {
        opacity: 0.12;
      }
    }

    &:hover {
      .mat-button-focus-overlay {
        opacity: 0.08;
      }
    }

    &:active, &.pressed {
      .mat-button-focus-overlay {
        opacity: 0.12;
      }
    }
  }
}

@mixin nm-outlined-button ($config) {
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);
  $foreground-color: mat.get-color-from-palette($foreground, nm-icon-button-outlined);
  $border-color: mat.get-color-from-palette($background, nm-outlined-button-border);

  border-color: $border-color;
  background-color: transparent;
  color: $foreground-color;

  .mat-progress-spinner circle, .mat-spinner circle {
    color: $foreground-color;
    stroke: $foreground-color;
  }

  &.mat-button-disabled {
    border-color: mat.get-color-from-palette($background, nm-button-disabled-12);
    color: mat.get-color-from-palette($foreground, nm-button-disabled-38);
  }

  &:not(.mat-button-disabled) {
    .mat-button-focus-overlay {
      background-color: $foreground-color;
    }

    &:focus-visible {
      border-color: $foreground-color;

      .mat-button-focus-overlay {
        opacity: 0.12;
      }
    }

    &:hover {
      .mat-button-focus-overlay {
        opacity: 0.08;
      }
    }

    &:active, &.pressed {
      .mat-button-focus-overlay {
        opacity: 0.12;
      }
    }
  }
}

@mixin nm-text-button ($config) {
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);
  $foreground-color: mat.get-color-from-palette($foreground, nm-icon-button-text);

  background-color: transparent;
  color: $foreground-color;

  &.mat-button-disabled {
    border-color: mat.get-color-from-palette($background, nm-button-disabled-12);
    color: mat.get-color-from-palette($foreground, nm-button-disabled-38);
  }

  &:not(.mat-button-disabled) {
    .mat-button-focus-overlay {
      background-color: $foreground-color;
    }

    &:focus-visible {
      .mat-button-focus-overlay {
        opacity: 0.12;
      }
    }

    &:hover {
      .mat-button-focus-overlay {
        opacity: 0.08;
      }
    }

    &:active, &.pressed {
      .mat-button-focus-overlay {
        opacity: 0.12;
      }
    }
  }
}

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $typography: mat.get-typography-config($theme);

  .mat-icon-button {
    font-size: mat.font-size($typography, nm-button);
    font-weight: mat.font-weight($typography, nm-button);
    letter-spacing: mat.letter-spacing($typography, nm-button);
    line-height: mat.line-height($typography, nm-button);
    color: mat.get-color-from-palette($foreground, nm-icon-button-text) !important;
  }

  .mat-primary.mat-icon-button {
    &.nm-filled-button {
      @include nm-filled-button($config);
    }

    &.nm-outlined-button {
      @include nm-outlined-button($config);
    }

    &.nm-text-button {
      @include nm-text-button($config);
    }

    &.nm-tonal-button {
      @include nm-tonal-button($config);
    }
  }
}

.nm-icon-button {
  &.mat-flat-button,
  &.mat-icon-button,
  &.mat-stroked-button {
    border-radius: 20px;
  }

  height: 2.5rem;

  &.full-witdh {
    width: 100%;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.5rem;
  }

  &.loading {
    .content {
      visibility: hidden;
    }
  }

  .mat-progress-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
