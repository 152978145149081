@use '@angular/material' as mat;
@use 'sass:map';

.nm-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin: 0 auto;
  padding-top: 80px;
  padding-bottom: 20px;

  &.layout-desktop {
    max-width: 600px;
  }

  &.layout-mobile {
    max-width: 288px;
  }

  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    overflow: hidden;
    max-width: inherit;
  }

  .title {
    display: block;
    width: 100%;
    text-align: center;
  }

  .body {
    text-align: center;
  }

  .image-container {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-bottom: 16px;

    width: 180px;
    height: 200px;
  }

  .image {
    position: absolute;

    width: 140px;
    height: 140px;

    object-fit: contain;
  }
}

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  $nm-empty-state-title: mat.get-color-from-palette($foreground, nm-empty-state-title);
  $nm-empty-state-body: mat.get-color-from-palette($foreground, nm-empty-state-body);

  .nm-empty-state {
    .title {
      color: $nm-empty-state-title;
      font-size: mat.font-size($typography, display-md-600);
      font-weight: mat.font-weight($typography, display-md-600);
      line-height: mat.line-height($typography, display-md-600);
      letter-spacing: mat.letter-spacing($typography, display-md-600);
    }

    &.layout-mobile {
      .title {
        font-size: mat.font-size($typography, display-xs-600);
        font-weight: mat.font-weight($typography, display-xs-600);
        line-height: mat.line-height($typography, display-xs-600);
        letter-spacing: mat.letter-spacing($typography, display-xs-600);
      }
    }

    .body {
      color: $nm-empty-state-body;
      font-size: mat.font-size($typography, text-sm-400);
      font-weight: mat.font-weight($typography, text-sm-400);
      line-height: mat.line-height($typography, text-sm-400);
      letter-spacing: mat.letter-spacing($typography, text-sm-400);
    }
  }
}
