@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);

  $nm-layout-loader-progress: mat.get-color-from-palette($foreground, nm-layout-loader-progress);

  .nm-layout-loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    height: 2px;
    overflow: hidden;

    .progressBar {
      background-color: $nm-layout-loader-progress;
      height: 100%;
      animation: 1s ease-out 0s 1 progressBarLoader;
    }

    @keyframes progressBarLoader {
      0% {
        width: 20%;
      }
      100% {
        width: 100%;
      }
    }

  }
}
