@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $typography: mat.get-typography-config($theme);

  .widget {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 16px;
    background-color: mat.get-color-from-palette($foreground, nm-widget-background);
    box-shadow: inset 0 0 0 1px mat.get-color-from-palette($foreground, nm-widget-border);

    .widget__content {
      padding: 16px 16px 0;

      .graph-loading, .skeleton {
        padding-bottom: 16px;
      }
    }

    .error {
      border-radius: 0 0 16px 16px;
      background-color: mat.get-color-from-palette($foreground, nm-widget-background-error);
    }

    .no-data {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 16px;
      color: mat.get-color-from-palette($foreground, nm-widget-no-data);
      font-size: mat.font-size($typography, nm-widget-no-data);
      font-weight: mat.font-weight($typography, nm-widget-no-data);
      letter-spacing: mat.letter-spacing($typography, nm-widget-no-data);
      line-height: mat.line-height($typography, nm-widget-no-data);
    }
  }
}
