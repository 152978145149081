@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);

  .back {
    &.is-first-level {
      padding-left: 12px;
    }
  }
}