@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $typography: mat.get-typography-config($theme);

  .filter-period {
    position: relative;

    ::ng-deep {
      .nm-form-field {
        .text-wrapper {
          position: static !important;
          user-select: none;
        }
      }
    }

    .field {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      visibility: hidden;
    }
  }

  .filter-period.calendar-panel {
    width: 256px;
    max-width: 100%;

    .mat-calendar {
      width: 100%;
    }

    .mat-calendar-table-header-divider {
      display: none;
    }

    .mat-calendar-body-label {
      opacity: 0;

      &[colspan="7"] {
        display: none;
      }
    }

    .mat-calendar-controls {
      margin-top: 0;
    }

    .controls {
      display: block;
      padding: 24px 0;
      margin: 0 auto;
      width: 198px;

      .cancel {
        display: block;
        margin-top: 8px;
      }
    }
  }
}
