@use '@angular/material' as mat;
@use 'sass:map';

:host {
  width: 100%;
}

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $typography: mat.get-typography-config($theme);

  .content-info-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      margin-top: 16px;
      display: block;
    }
  
    .image {
      display: block;
      width: 100%;
      height: 340px;
      border-radius: 20px;
      object-fit: cover;
    }
  
    .header-row {
      display: flex;
      justify-content: space-between;
  
      .header {
        font-size: mat.font-size($typography, nm-content-info-card-header);
        font-weight: mat.font-weight($typography, nm-content-info-card-header);
        letter-spacing: mat.letter-spacing($typography, nm-content-info-card-header);
        line-height: mat.line-height($typography, nm-content-info-card-header);
        color: mat.get-color-from-palette($foreground, nm-content-info-card-header);

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        max-height: 96px;
      }
    }
  
    .sub-header {
      margin-top: 8px;
      font-size: mat.font-size($typography, nm-content-info-card-sub-header);
      font-weight: mat.font-weight($typography, nm-content-info-card-sub-header);
      letter-spacing: mat.letter-spacing($typography, nm-content-info-card-sub-header);
      line-height: mat.line-height($typography, nm-content-info-card-sub-header);
      color: mat.get-color-from-palette($foreground, nm-content-info-card-sub-header);
    }

    .card-content {
      margin-top: 16px;
    }
  
    .content-header {
      font-size: mat.font-size($typography, nm-content-info-card-sub-header);
      font-weight: mat.font-weight($typography, nm-content-info-card-sub-header);
      letter-spacing: mat.letter-spacing($typography, nm-content-info-card-sub-header);
      line-height: mat.line-height($typography, nm-content-info-card-sub-header);
      color: mat.get-color-from-palette($foreground, nm-content-info-card-header);

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      max-height: 60px;
      margin-bottom: 8px;
    }
  
    .content-slot {
      font-size: mat.font-size($typography, nm-content-info-card-content);
      font-weight: mat.font-weight($typography, nm-content-info-card-content);
      letter-spacing: mat.letter-spacing($typography, nm-content-info-card-content);
      line-height: mat.line-height($typography, nm-content-info-card-content);
      color: mat.get-color-from-palette($foreground, nm-content-info-card-sub-header);
    }
  
    .action {
      margin-top: 24px;

      .counter-panel {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .counter {
          display: flex;
          flex: 1;
          align-items: center;
          gap: 21px;

          nm-input {
            width: 80px;
          }

          nm-svg-icon {
            cursor: pointer;
            user-select: none;
          }
        }

        .counter-text {
          flex: 1;
          align-self: center;
          text-align: right;
          font-size: mat.font-size($typography, nm-content-info-card-counter);
          font-weight: mat.font-weight($typography, nm-content-info-card-counter);
          letter-spacing: mat.letter-spacing($typography, nm-content-info-card-counter);
          line-height: mat.line-height($typography, nm-content-info-card-counter);
          color: mat.get-color-from-palette($foreground, nm-content-info-card-header);
        }
      }
    }
  
    .caption {
      margin-top: 4px;
      text-align: center;
      font-size: mat.font-size($typography, nm-content-info-card-sub-caption);
      font-weight: mat.font-weight($typography, nm-content-info-card-sub-caption);
      letter-spacing: mat.letter-spacing($typography, nm-content-info-card-sub-caption);
      line-height: mat.line-height($typography, nm-content-info-card-sub-caption);
      color: mat.get-color-from-palette($foreground, nm-content-info-card-sub-header);
    }

    .content-info-card-label {
      padding: 2px 12px;
      border-radius: 12px;
      margin-left: 16px;
      font-size: mat.font-size($typography, nm-content-info-card-content);
      font-weight: mat.font-weight($typography, nm-content-info-card-content);
      letter-spacing: mat.letter-spacing($typography, nm-content-info-card-content);
      line-height: mat.line-height($typography, nm-content-info-card-content);
      color: mat.get-color-from-palette($foreground, nm-content-info-card-content);
      background: mat.get-color-from-palette($foreground, nm-content-info-card-label);
    }
  }
}
