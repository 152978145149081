@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $typography: mat.get-typography-config($theme);

  $nm-tooltip: mat.get-color-from-palette($foreground, nm-tooltip);
  $nm-tooltip-color: mat.get-color-from-palette($foreground, nm-tooltip-color);

  .tooltip {
    max-width: 390px;
    padding: 4px 16px;
    animation: fade-in-animation ease 0.3s;
    background-color: $nm-tooltip;
    border-radius: 4px;
    color: $nm-tooltip-color;
    font-size: mat.font-size($typography, text-xs-400);
    font-weight: mat.font-weight($typography, text-xs-400);
    letter-spacing: mat.letter-spacing($typography, text-xs-400);
    line-height: mat.line-height($typography, text-xs-400);
  }

  @keyframes fade-in-animation {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}
