@use '@angular/material' as mat;
@use 'sass:map';

:host {
  display: inline-block;
  line-height: 0;
}

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $typography: mat.get-typography-config($theme);

  $nm-badge: mat.get-color-from-palette($foreground, nm-badge);
  $nm-badge-count: mat.get-color-from-palette($foreground, nm-badge-count);

  .nm-badge {
    display: inline-block;
    width: 6px;
    height: 6px;
    background-color: $nm-badge;
    border-radius: 3px;

    &--count {
      display: inline-flex;
      height: 16px;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
    }

    &--one {
      width: 16px;
    }

    &--two {
      width: 22px;
    }

    &--three {
      width: 28px;
    }
  }

  .nm-badge__count {
    color: $nm-badge-count;
    font-size: mat.font-size($typography, text-2xs-500);
    font-weight: mat.font-weight($typography, text-2xs-500);
    letter-spacing: mat.letter-spacing($typography, text-2xs-500);
    line-height: mat.line-height($typography, text-2xs-500);
  }
}
