@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  $nm-menu-item-hover: mat.get-color-from-palette($background, nm-menu-item-hover);
  $nm-menu-item-focus: mat.get-color-from-palette($background, nm-menu-item-focus);
  $nm-menu-item-selected: mat.get-color-from-palette($background, nm-menu-item-selected);
  $nm-menu-item-selected-hover: mat.get-color-from-palette($background, nm-menu-item-selected-hover);
  $nm-menu-item-selected-focus: mat.get-color-from-palette($background, nm-menu-item-selected-focus);

  $nm-menu-item-label: mat.get-color-from-palette($foreground, nm-menu-item-label);

  .filter-compare-selector {
    .item {
      nm-radio {
        margin-right: 14px;
      }

      &.mat-menu-item {
        display: flex;
        align-items: center;
        min-height: 48px;
        padding: 4px 12px;

        .radio-button {
          margin-right: 12px;
          display: inline;
        }

        .label {
          cursor: pointer;
          user-select: none;
          overflow: hidden;
          text-overflow: ellipsis;
          color: $nm-menu-item-label;
          font-size: mat.font-size($typography, text-sm-500);
          font-weight: mat.font-weight($typography, text-sm-500);
          letter-spacing: mat.letter-spacing($typography, text-sm-500);
          line-height: mat.line-height($typography, text-sm-500);
        }

        &:hover:not([disabled]) {
          background-color: $nm-menu-item-hover;
        }

        &:focus:not([disabled]) {
          background-color: $nm-menu-item-focus;
        }

        &.nm-menu-item-selected {
          background-color: $nm-menu-item-selected;

          &:hover:not([disabled]) {
            background-color: $nm-menu-item-selected-hover;
          }

          &:focus:not([disabled]) {
            background-color: $nm-menu-item-selected-focus;
          }
        }
      }
    }
  }
}
