@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $typography: mat.get-typography-config($theme);

  $nm-paginator-count: mat.get-color-from-palette($foreground, nm-paginator-count);
  $nm-paginator-hovered: mat.get-color-from-palette($foreground, nm-paginator-hovered);
  $nm-paginator-focused: mat.get-color-from-palette($foreground, nm-paginator-focused);
  $nm-paginator-pressed: mat.get-color-from-palette($foreground, nm-paginator-pressed);
  $nm-paginator-selected: mat.get-color-from-palette($foreground, nm-paginator-selected);
  $nm-paginator-disabled: mat.get-color-from-palette($foreground, nm-paginator-disabled);
  $nm-paginator-selected-disabled: mat.get-color-from-palette($foreground, nm-paginator-selected-disabled);
  $nm-paginator-selected-hovered: mat.get-color-from-palette($foreground, nm-paginator-selected-hovered);
  $nm-paginator-selected-focused: mat.get-color-from-palette($foreground, nm-paginator-selected-focused);
  $nm-paginator-selected-pressed: mat.get-color-from-palette($foreground, nm-paginator-selected-pressed);

  .nm-paginator {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    @media (hover: hover) {
      &:hover {
        .nm-paginator__layer {
          background-color: $nm-paginator-hovered;
        }
      }
    }

    &:focus {
      outline: none;
    }

    .nm-paginator__layer {
      position: absolute;
      width: 100%;
      height: 100%;
      transition: background-color 0.1s ease-in-out;
    }

    &:focus-visible {
      outline: none;

      .nm-paginator__layer {
        background-color: $nm-paginator-focused;
      }
    }

    &:active {
      .nm-paginator__layer {
        background-color: $nm-paginator-pressed;
      }
    }

    &.selected {
      background-color: $nm-paginator-selected;

      @media (hover: hover) {
        &:hover {
          .nm-paginator__layer {
            background-color: $nm-paginator-selected-hovered;
          }
        }
      }

      &:focus-visible {
        .nm-paginator__layer {
          background-color: $nm-paginator-selected-focused;
        }
      }

      &:active {
        .nm-paginator__layer {
          background-color: $nm-paginator-selected-pressed;
        }
      }
    }

    .nm-paginator__count,
    .nm-paginator__skip {
      color: $nm-paginator-count;
      font-size: mat.font-size($typography, text-sm-500);
      font-weight: mat.font-weight($typography, text-sm-500);
      letter-spacing: mat.letter-spacing($typography, text-sm-500);
      line-height: mat.line-height($typography, text-sm-500);
    }

    &.skip {
      background-color: transparent;
      pointer-events: none;
    }

    &:disabled {
      pointer-events: none;

      &.selected {
        background-color: $nm-paginator-selected-disabled;
      }

      .nm-paginator__count,
      .nm-paginator__skip {
        color: $nm-paginator-disabled;
      }
    }
  }
}
