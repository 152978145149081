@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $typography: mat.get-typography-config($theme);

  $nm-table-name: mat.get-color-from-palette($foreground, nm-table-name);
  $nm-table-button: mat.get-color-from-palette($foreground, nm-table-button);
  $nm-table-thin: mat.get-color-from-palette($foreground, nm-table-thin);

  .table-title {
    .name-container {
      display: flex;
      flex-grow: 1;
      flex-basis: 30%;
      flex-direction: column;
      min-width: 64px;
      user-select: none;
      align-items: flex-start;

      .description {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-wrap: break-word;
        font-size: mat.font-size($typography, text-xs-400);
        font-weight: mat.font-weight($typography, text-xs-400);
        letter-spacing: mat.letter-spacing($typography, text-xs-400);
        line-height: mat.line-height($typography, text-xs-400);
        color: mat.get-color-from-palette($foreground, nm-list-item-label-secondary);
      }
    }

    .name {
      font-size: mat.font-size($typography, text-sm-400);
      font-weight: mat.font-weight($typography, text-sm-400);
      letter-spacing: mat.letter-spacing($typography, text-sm-400);
      line-height: mat.line-height($typography, text-sm-400);
      color: $nm-table-name;
    }

    .button {
      font-size: mat.font-size($typography, text-sm-400);
      font-weight: mat.font-weight($typography, text-sm-400);
      letter-spacing: mat.letter-spacing($typography, text-sm-400);
      line-height: mat.line-height($typography, text-sm-400);
      color: $nm-table-button;
    }

    &.font-bold {
      .name, .button {
        font-size: mat.font-size($typography, text-sm-500);
        font-weight: mat.font-weight($typography, text-sm-500);
        letter-spacing: mat.letter-spacing($typography, text-sm-500);
        line-height: mat.line-height($typography, text-sm-500);
      }
    }

    &.font-thin {
      .name, .button {
        color: $nm-table-thin;
        font-size: mat.font-size($typography, text-xs-400);
        font-weight: mat.font-weight($typography, text-xs-400);
        letter-spacing: mat.letter-spacing($typography, text-xs-400);
        line-height: mat.line-height($typography, text-xs-400);
      }
    }

    nm-svg-icon {
      min-width: 24px;
    }
  }
}

:host {
  .table-title {
    display: flex;
    align-items: center;
    column-gap: 8px;

    .button {
      cursor: pointer;
    }
  }
}
