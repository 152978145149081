@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);

  .nm-drag-handler {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 18px;
    height: 24px;
    border-radius: 4px;

    nm-svg-icon {
      width: 8px;
      height: 14px;
    }

    &.mouse-down {
      background: mat.get-color-from-palette($foreground, nm-table-drag-pressed);
    }

    &:hover {
      background: mat.get-color-from-palette($foreground, nm-table-drag-hover);
    }
  }
}