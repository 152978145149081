@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  .invite {
    width: 360px;

    h2 {
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      color: mat.get-color-from-palette($foreground, nm-form-field-text);
    }

    .description {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.0025em;
      color: mat.get-color-from-palette($foreground, nm-form-field-placeholder);
    }

    .allow {
      margin-top: 24px;
      width: 100%;
    }

    .to-organization {
      margin-top: 18px;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.0025em;
      cursor: pointer;
      color: mat.get-color-from-palette($foreground, nm-form-field-placeholder);

      nm-checkbox {
        margin-right: 15px;
      }
    }

    .decline {
      margin-top: 34px;
    }

  }
}
