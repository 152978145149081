@use '@angular/material' as mat;
@use 'sass:map';
@use 'app/layout/sidenav-layout/sidenav-layout.component';
@use 'app/shared/component/table/table.component';
@use 'app/shared/component/input/input.component';
@use 'app/shared/component/button/button.component';
@use 'app/shared/component/icon-button/icon-button.component';
@use 'app/shared/component/radio/radio.component';
@use 'app/shared/component/checkbox/checkbox.component';
@use 'app/shared/component/switch/switch.component';
@use 'app/shared/component/control-hint/control-hint.component';
@use 'app/shared/component/breadcrumbs/breadcrumbs.component';
@use 'app/shared/component/form-field/form-field.component';
@use 'app/shared/component/control-label/control-label.component';
@use 'app/shared/component/badge/badge.component';
@use 'app/shared/component/divider/divider.component';
@use 'app/shared/component/chip/chip.component';
@use 'app/shared/component/form-group/form-group.component';
@use 'app/shared/component/form/form.component';
@use 'app/shared/component/tab/tab.component';
@use 'app/shared/component/menu/menu.component';
@use 'app/shared/component/menu/menu-content/menu-content.component';
@use 'app/shared/component/menu-item/menu-item.component';
@use 'app/shared/component/datepicker/datepicker.component';
@use 'app/shared/component/table/drag-handler/drag-handler.component';
@use 'app/shared/component/table/table-header/table-header.component';
@use 'app/shared/component/table/table-row/table-row.component';
@use 'app/shared/component/table/table-total-rows/table-total-rows.component';
@use 'app/shared/component/tag/tag.component';
@use 'app/shared/component/dialog/modal-base/modal-base.component';
@use 'app/shared/component/avatar/avatar.component';
@use 'app/shared/directive/tooltip/tooltip-component/tooltip.component';
@use 'app/shared/component/alert/alert.component';
@use 'app/shared/component/paginator/paginator.component';
@use 'app/shared/component/paginations/paginations.component';
@use 'app/shared/component/input-image/input-image.component';
@use 'app/shared/component/input-file/input-file.component';
@use 'app/shared/component/top-bar/top-bar.component';
@use 'app/shared/component/navigation-drawer/navigation-drawer.component';
@use 'app/shared/component/skeleton-templates/skeleton-template-table/skeleton-template-table.component';
@use 'app/shared/component/empty-state/empty-state.component';
@use 'app/shared/component/banner/banner.component';
@use 'app/shared/component/menu-header/menu-header.component';
@use 'app/shared/component/progress-bar/progress-bar.component';
@use 'app/shared/component/progress-spinner/progress-spinner.component';
@use 'app/shared/component/autocomplete/autocomplete.component';
@use 'app/shared/component/footer/footer.component';
@use 'app/shared/component/table-title/table-title.component';
@use 'app/shared/component/filter-checkbox/filter-checkbox.component';
@use 'app/shared/component/filter-radio/filter-radio.component';
@use 'app/shared/component/filter-panel/filter-panel.component';
@use 'app/shared/component/filter-period/filter-period.component';
@use 'app/shared/component/filter-for-days/filter-for-days.component';
@use 'app/shared/component/graph/graph.component';
@use 'app/shared/component/dropdown-menu/dropdown-menu.component';
@use 'app/shared/component/filter-header/filter-header.component';
@use 'app/shared/component/filter-period-with-mode/filter-period-with-mode.component';
@use 'app/shared/component/filter-period-new/filter-period-new.component';
@use 'app/shared/component/filter-select-with-compare/filter-selector-mode/filter-selector-mode.component';
@use 'app/shared/component/layout-loader/layout-loader.component';
@use 'app/shared/component/micro-banner/micro-banner.component';
@use 'app/shared/component/alert-banner/alert-banner.component';
@use 'app/shared/component/content-info-card/content-info-card.component';
@use 'app/shared/component/content-card/content-card.component';
@use 'app/shared/component/list-item/list-item.component';
@use 'app/shared/component/widget-header/widget-header.component';
@use 'app/shared/component/widget/widget.component';
@use 'app/shared/component/widget-report/widget-report.component';
@use 'app/shared/component/order-state/order-state.component';
@use 'app/shared/component/metric/metric.component';
@use 'app/shared/component/metric-list/metric-list.component';
@use 'app/shared/component/table-header-cell-with-menu/table-header-cell-with-menu.component';
@use 'app/shared/component/filter-label/filter-label.component';
@use 'app/shared/component/input-search/input-search.component';
@use 'app/shared/component/control-panel/control-panel.component';
@use 'app/shared/component/filters-table/filters-table.component';

@use 'app/layout/base-layout/base-layout-footer-buttons/base-layout-footer-buttons.component';
@use 'app/layout/base-layout/base-layout.component';
@use 'app/modules/auth/page/login/login.component';
@use 'app/modules/auth/page/create-organization/create-organization.component';
@use 'app/modules/auth/page/invite/invite-expired/invite-expired.component';
@use 'app/modules/auth/page/invite/invite/invite.component';
@use 'app/modules/settings/pages/stores/store/tables/tables.component';
@use 'app/modules/auth/component/organization-item/organization-item.component';
@use 'app/modules/settings/pages/subscriptions/subscription-change/subscription-change.component';

@use 'app/shared/directive/drag-and-drop/drag-and-drop.directive';

@import 'media-vars';
@import '~normalize.css';

@import 'assets/style/fonts.css';
@import 'assets/style/theme/typography.scss';
@import 'assets/style/theme/colors.scss';
@import 'assets/style/theme/theme.scss';
@import 'assets/style/theme/dark.scss';
@import 'assets/style/theme/light.scss';
@import 'assets/style/app';

@mixin nomia-theme($theme) {
  @include sidenav-layout.theme($theme);
  @include table.theme($theme);
  @include input.theme($theme);
  @include button.theme($theme);
  @include icon-button.theme($theme);
  @include chip.theme($theme);
  @include control-hint.theme($theme);
  @include radio.theme($theme);
  @include checkbox.theme($theme);
  @include switch.theme($theme);
  @include form-field.theme($theme);
  @include control-label.theme($theme);
  @include badge.theme($theme);
  @include divider.theme($theme);
  @include form-group.theme($theme);
  @include form.theme($theme);
  @include tab.theme($theme);
  @include menu.theme($theme);
  @include menu-item.theme($theme);
  @include menu-content.theme($theme);
  @include datepicker.theme($theme);
  @include drag-handler.theme($theme);
  @include table-header.theme($theme);
  @include table-row.theme($theme);
  @include table-total-rows.theme($theme);
  @include skeleton-template-table.theme($theme);
  @include tag.theme($theme);
  @include modal-base.theme($theme);
  @include avatar.theme($theme);
  @include tooltip.theme($theme);
  @include alert.theme($theme);
  @include paginator.theme($theme);
  @include paginations.theme($theme);
  @include input.theme($theme);
  @include input-image.theme($theme);
  @include input-file.theme($theme);
  @include top-bar.theme($theme);
  @include navigation-drawer.theme($theme);
  @include empty-state.theme($theme);
  @include banner.theme($theme);
  @include menu-header.theme($theme);
  @include progress-bar.theme($theme);
  @include progress-spinner.theme($theme);
  @include autocomplete.theme($theme);
  @include breadcrumbs.theme($theme);
  @include footer.theme($theme);
  @include table-title.theme($theme);
  @include filter-checkbox.theme($theme);
  @include filter-radio.theme($theme);
  @include filter-panel.theme($theme);
  @include filter-period.theme($theme);
  @include filter-for-days.theme($theme);
  @include graph.theme($theme);
  @include dropdown-menu.theme($theme);
  @include filter-header.theme($theme);
  @include filter-period-with-mode.theme($theme);
  @include filter-period-new.theme($theme);
  @include filter-selector-mode.theme($theme);
  @include layout-loader.theme($theme);
  @include base-layout-footer-buttons.theme($theme);
  @include base-layout.theme($theme);
  @include create-organization.theme($theme);
  @include invite-expired.theme($theme);
  @include invite.theme($theme);
  @include tables.theme($theme);
  @include organization-item.theme($theme);
  @include drag-and-drop.theme($theme);
  @include micro-banner.theme($theme);
  @include alert-banner.theme($theme);
  @include content-info-card.theme($theme);
  @include content-card.theme($theme);
  @include list-item.theme($theme);
  @include widget-header.theme($theme);
  @include widget.theme($theme);
  @include widget-report.theme($theme);
  @include order-state.theme($theme);
  @include metric.theme($theme);
  @include metric-list.theme($theme);
  @include table-header-cell-with-menu.theme($theme);
  @include filter-label.theme($theme);
  @include input-search.theme($theme);
  @include control-panel.theme($theme);
  @include filters-table.theme($theme);
  @include subscription-change.theme($theme);
}

.theme-light {
  @include nomia-theme($theme-light);
  @include mat.all-component-themes($theme-light);

  $config: mat.get-color-config($theme-light);
  $foreground: map.get($config, foreground);

  .spinner {
    width: 24px;
    height: 24px;
    border: 3px solid mat.get-color-from-palette($foreground, preloader);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
}

.theme-dark {
  @include nomia-theme($theme-dark);
  @include mat.all-component-themes($theme-dark);

  $config: mat.get-color-config($theme-dark);
  $foreground: map.get($config, foreground);

  .spinner {
    width: 24px;
    height: 24px;
    border: 3px solid mat.get-color-from-palette($foreground, preloader);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
}

@include mat.core($nomia-typography);

body {
  overscroll-behavior-y: none;
  position: fixed;
  overflow: hidden;
  width: 100vw;
  height: 100svh;

  -webkit-tap-highlight-color: transparent;
  outline: none;

  &.theme-light {
    $config: mat.get-color-config($theme-light);
    $background: map.get($config, background);
    background-color: mat.get-color-from-palette($background, background-layout);
    ::ng-deep .mat-drawer {
      background-color: mat.get-color-from-palette($background, background-layout);
    }
  }

  &.theme-dark {
    $config: mat.get-color-config($theme-dark);
    $background: map.get($config, background);
    background-color: mat.get-color-from-palette($background, background-layout);
    ::ng-deep{
      .mat-drawer {
        background-color: mat.get-color-from-palette($background, background-layout);
      }
    }
  }
}

.application-loading-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  font-family:'Inter', sans-serif !important;
}
a,p,span,h1,h2,h3,h4,h5,h6,li,input,textarea {
  font-family:'Inter', sans-serif !important;
}
