@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);
  $typography: mat.get-typography-config($theme);

  $nm-input-border-readonly: mat.get-color-from-palette($foreground, nm-input-border-readonly);
  $nm-input-color-readonly: mat.get-color-from-palette($foreground, nm-input-color-readonly);
  $nm-input-text: mat.get-color-from-palette($foreground, nm-input-text);

  .nm-input-control {
    display: flex;
    flex-direction: column;
    row-gap: 4px;

    input {
      user-select: none;
    }

    .nm-input-text:empty::before {
      content: '\200b'; // unicode zero width space character
    }

    &.nm-input-control-readonly {
      .nm-form-field {
        box-shadow: inset 0 0 0 1px $nm-input-border-readonly;
        color: $nm-input-color-readonly;

        &:hover, &:active, &:focus-within, &.focused {
          box-shadow: inset 0 0 0 1px $nm-input-border-readonly;
        }
      }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      border: none;
      transition: background-color 5000s ease-in-out 0s;
    }

  }
}
