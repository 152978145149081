@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  .filter-for-days-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .filter-set {
    display: flex;
    flex-direction: row;

    .filter-set-column {
      width: 50%;
    }
  }

  .filter-for-days-body {
    .days-in-filter {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 4px;
      padding: 12px;

      .day-in-filter {
        width: 36px;
        height: 28px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px;
        cursor: pointer;

        background: mat.get-color-from-palette($foreground, nm-filter-for-days-day);
        color: mat.get-color-from-palette($foreground, nm-filter-for-days-text);
        font-size: mat.font-size($typography, nm-filter-header);
        font-weight: mat.font-weight($typography, nm-filter-header);
        letter-spacing: mat.letter-spacing($typography, nm-filter-header);
        line-height: mat.line-height($typography, nm-filter-header);

        &.active {
          background: mat.get-color-from-palette($foreground, nm-filter-for-days-day-active);
          color: mat.get-color-from-palette($foreground, nm-filter-for-days-text-active);
        }
      }
    }

    .inputs-in-filter {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 12px;
      gap: 16px;

      .nm-input-control {
        width: 140px;
      }

      .nm-autocomplete {
        width: 140px;
      }
    }

    .nm-control-hint {
      padding: 0 12px 4px;
    }
  }

  .controls-button {
    display: flex;
    flex-direction: row;
    padding: 12px;
    gap: 8px;
  }

  .filter-res {
    display: flex;
    flex-direction: column;
    padding: 2px 12px;

    .filter-res-title{
      padding-top: 4px;
      color: mat.get-color-from-palette($foreground, nm-filter-for-days-text);
      font-size: mat.font-size($typography, nm-filter-header);
      font-weight: mat.font-weight($typography, nm-filter-header);
      letter-spacing: mat.letter-spacing($typography, nm-filter-header);
      line-height: mat.line-height($typography, nm-filter-header);
    }

    .filter-res-text{
      color: mat.get-color-from-palette($foreground, nm-filter-header-color);
      font-size: mat.font-size($typography, text-xs-400);
      font-weight: mat.font-weight($typography, text-xs-400);
      letter-spacing: mat.letter-spacing($typography, text-xs-400);
      line-height: mat.line-height($typography, text-xs-400);
    }
  }
}

.filter-for-days-tabs {
  ::ng-deep {
    .mat-ripple{
      min-width: 50% !important;
    }
  }
}
