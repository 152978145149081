@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $typography: mat.get-typography-config($theme);

  $nm-form-group-title: mat.get-color-from-palette($foreground, nm-form-group-title);
  $nm-form-group-icon: mat.get-color-from-palette($foreground, nm-form-group-icon);
  $nm-form-group-description: mat.get-color-from-palette($foreground, nm-form-group-description);

  .nm-form-group {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .nm-form-group-header {
      display: flex;
      column-gap: 16px;
      max-width: 552px;
    }
  }

  .nm-form-group__container {
    display: flex;
    width: 100%;
    flex-direction: column;
    row-gap: 8px;
  }

  .title-row {
    display: grid;
    grid-column-gap: 16px;
    grid-template-columns: minmax(0, 1fr) auto;
  }

  .nm-form-group__title {
    display: flex;
    min-height: 40px;
    align-items: center;
    color: $nm-form-group-title;
    font-size: mat.font-size($typography, text-md-600);
    font-weight: mat.font-weight($typography, text-md-600);
    letter-spacing: mat.letter-spacing($typography, text-md-600);
    line-height: mat.line-height($typography, text-md-600);

    .title-content {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .nm-form-group__description {
    display: flex;
    align-items: center;
    color: $nm-form-group-description;
    font-size: mat.font-size($typography, text-sm-400);
    font-weight: mat.font-weight($typography, text-sm-400);
    letter-spacing: mat.letter-spacing($typography, text-sm-400);
    line-height: mat.line-height($typography, text-sm-400);
  }

  .nm-form-group__action {
    button {
      color: $nm-form-group-icon;
    }
  }

  .nm-form-group__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    row-gap: 24px;

    &:empty {
      display: none;
    }
  }
}
