@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $typography: mat.get-typography-config($theme);

  $nm-footer-border: mat.get-color-from-palette($foreground, nm-footer-border);

  .nm-footer {
    .text {
      font-size: mat.font-size($typography, nm-footer-text);
      font-weight: mat.font-weight($typography, nm-footer-text);
      letter-spacing: mat.letter-spacing($typography, nm-footer-text);
      line-height: mat.line-height($typography, nm-footer-text);
    }

    nm-svg-icon {
      color: mat.get-color-from-palette($foreground, nm-footer-icon);
    }
  }
}

:host(.full-width) {
  width: 100%;
}

.nm-footer {
  display: flex;

  .text-container {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  .left-block {
    display: flex;
    flex-grow: 1;
    column-gap: 16px;
  }

  .right-block {
    display: flex;
    flex-grow: 0;
    margin-left: auto;
    column-gap: 16px;
  }

  &.mobile {
    .left-block {
      nm-button:not(:first-child) {
        display: none;
      }
    }

    .right-block {
      nm-button {
        display: none;
      }
    }
  }
}
