@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  .tables {
    .show-tables {
      display: flex;
      gap: 24px;
      margin-bottom: 24px;
    }

    .group-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 24px;

      .name {
        font-size: mat.font-size($typography, nm-tables-description);
        font-weight: mat.font-weight($typography, nm-tables-description);
        line-height: mat.line-height($typography, nm-tables-description);
        letter-spacing: mat.letter-spacing($typography, nm-tables-description);
        color: mat.get-color-from-palette($foreground, nm-tables-description);

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .controls {
        display: flex;
        gap: 8px;
        padding: 0;
      }
    }

    .show-tables-description {
      max-width: 500px;
      width: 500px;
      color: mat.get-color-from-palette($foreground, nm-tables-text);

      .title {
        font-size: mat.font-size($typography, nm-tables-description);
        font-weight: mat.font-weight($typography, nm-tables-description);
        line-height: mat.line-height($typography, nm-tables-description);
        letter-spacing: mat.letter-spacing($typography, nm-tables-description);
        color: mat.get-color-from-palette($foreground, nm-tables-description);
      }
    }

    .show-tables-switcher {
      font-size: mat.font-size($typography, nm-tables-switcher);
      font-weight: mat.font-weight($typography, nm-tables-switcher);
      line-height: mat.line-height($typography, nm-tables-switcher);
      letter-spacing: mat.letter-spacing($typography, nm-tables-switcher);
      color: mat.get-color-from-palette($foreground, nm-tables-switcher);
    }
  }

  .table-groups-modal{
    .group-amounts {
      display: flex;
      margin-top: 24px;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 16px;

      nm-input {
        flex: 1;
      }
    }

    .tables-group-switcher {
      display: flex;
      margin-top: 24px;
      justify-content: space-between;
      gap: 24px;

      font-size: mat.font-size($typography, nm-tables-switcher);
      font-weight: mat.font-weight($typography, nm-tables-switcher);
      line-height: mat.line-height($typography, nm-tables-switcher);
      letter-spacing: mat.letter-spacing($typography, nm-tables-switcher);
      color: mat.get-color-from-palette($foreground, nm-tables-switcher);

      .title {
        font-size: mat.font-size($typography, nm-tables-description);
        font-weight: mat.font-weight($typography, nm-tables-description);
        line-height: mat.line-height($typography, nm-tables-description);
        letter-spacing: mat.letter-spacing($typography, nm-tables-description);
        color: mat.get-color-from-palette($foreground, nm-tables-description);
      }

      .description {
        flex: 1;
      }
    }
  }
}

:host ::ng-deep .row {
  position: relative;
}
