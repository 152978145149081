@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  .invite-expired {
    width: 360px;

    h2 {
      margin: 0;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      color: mat.get-color-from-palette($foreground, nm-form-field-text);
    }

    .description {
      margin-top: 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.0025em;
      color: mat.get-color-from-palette($foreground, nm-form-field-placeholder);
    }
  }
}
