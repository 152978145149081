@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  $nm-menu-item-hover: mat.get-color-from-palette($background, nm-menu-item-hover);
  $nm-menu-item-focus: mat.get-color-from-palette($background, nm-menu-item-focus);
  $nm-menu-item-selected: mat.get-color-from-palette($background, nm-menu-item-selected);
  $nm-menu-item-selected-hover: mat.get-color-from-palette($background, nm-menu-item-selected-hover);
  $nm-menu-item-selected-focus: mat.get-color-from-palette($background, nm-menu-item-selected-focus);

  $nm-menu-item-label: mat.get-color-from-palette($foreground, nm-menu-item-label);
  $nm-menu-item-label-disabled: mat.get-color-from-palette($foreground, nm-menu-item-label-disabled);
  $nm-menu-item-description: mat.get-color-from-palette($foreground, nm-menu-item-description);
  $nm-menu-item-description-disabled: mat.get-color-from-palette($foreground, nm-menu-item-description-disabled);
  $nm-menu-item-suffix: mat.get-color-from-palette($foreground, nm-menu-item-suffix);
  $nm-menu-item-suffix-disabled: mat.get-color-from-palette($foreground, nm-menu-item-suffix-disabled);
  $nm-menu-item-icon: mat.get-color-from-palette($foreground, nm-menu-item-icon);
  $nm-menu-item-icon-disabled: mat.get-color-from-palette($foreground, nm-menu-item-icon-disabled);
  $nm-menu-item-divider: mat.get-color-from-palette($foreground, nm-menu-item-divider);

  $nm-checkbox-checked: mat.get-color-from-palette($foreground, nm-checkbox-checked);

  .nm-menu-item {
    &.mat-menu-item {
      display: flex;
      flex-direction: row;
      height: auto;
      min-height: 48px;
      padding: 4px 12px;
      align-items: center;

      .content-container {
        overflow: hidden;
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        gap: 12px;
        align-items: center;
      }

      .label {
        cursor: pointer;
        user-select: none;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $nm-menu-item-label;
        font-size: mat.font-size($typography, text-sm-500);
        font-weight: mat.font-weight($typography, text-sm-500);
        letter-spacing: mat.letter-spacing($typography, text-sm-500);
        line-height: mat.line-height($typography, text-sm-500);
      }

      .description {
        overflow: hidden;
        text-overflow: ellipsis;
        color: $nm-menu-item-description;
        font-size: mat.font-size($typography, text-xs-400);
        font-weight: mat.font-weight($typography, text-xs-400);
        letter-spacing: mat.letter-spacing($typography, text-xs-400);
        line-height: mat.line-height($typography, text-xs-400);
      }

      .suffix {
        padding: 2px 0;
        color: $nm-menu-item-suffix;
        font-size: mat.font-size($typography, text-sm-500);
        font-weight: mat.font-weight($typography, text-sm-500);
        letter-spacing: mat.letter-spacing($typography, text-sm-500);
        line-height: mat.line-height($typography, text-sm-500);
      }

      .left {
        overflow: hidden;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 12px;
        white-space: initial;
      }

      &.nm-menu-item-description {
        .left > :not(.text) {
          margin-top: 8px;
        }
        //
        //.right > * {
        //  margin-top: 8px;
        //}
      }

      .text {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        padding: 2px 0;

        &.multiline {
          .label, .description {
            white-space: pre-line;
          }
        }
      }

      .right {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-left: auto;
        gap: 12px;
        align-items: center;

        nm-svg-icon.svg-check {
          color: mat.get-color-from-palette($foreground, nm-filter-for-days-day-active);
        }
      }

      .color {
        width: 24px;
        min-width: 24px;
        height: 24px;
        min-height: 24px;
        border-radius: 4px;
      }

      nm-svg-icon {
        min-width: 24px;
        min-height: 24px;
        color: $nm-menu-item-icon;
      }

      &:hover:not([disabled]) {
        background-color: $nm-menu-item-hover;
      }

      &:focus:not([disabled]) {
        background-color: $nm-menu-item-focus;
      }

      &.nm-menu-item-selected {
        background-color: $nm-menu-item-selected;

        &:hover:not([disabled]) {
          background-color: $nm-menu-item-selected-hover;
        }

        &:focus:not([disabled]) {
          background-color: $nm-menu-item-selected-focus;
        }
      }

      &[disabled] {
        .label {
          color: $nm-menu-item-label-disabled;
        }

        .description {
          color: $nm-menu-item-description-disabled;
        }

        .suffix {
          color: $nm-menu-item-suffix-disabled;
        }

        nm-svg-icon {
          color: $nm-menu-item-icon-disabled;
        }
      }

      .mat-checkbox .mat-checkbox-ripple, .mat-radio-ripple {
        display: none;
      }
    }

    .mat-checkbox-checked.mat-accent .mat-checkbox-background {
      background-color: $nm-checkbox-checked;
    }
  }

  .depth {
    min-width: 24px;
    display: flex;
    justify-content: center;
  }
}
