@use '@angular/material' as mat;
@use 'sass:map';

:host {
  display: inline-block;
  line-height: 0;
}

::ng-deep {
  .mat-checkbox {
    .mat-checkbox-layout {
      .mat-checkbox-label {
        display: none;
      }
    }
  }
}

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);

  $nm-checkbox: mat.get-color-from-palette($foreground, nm-checkbox);
  $nm-checkbox-checked: mat.get-color-from-palette($foreground, nm-checkbox-checked);
  $nm-checkbox-disabled: mat.get-color-from-palette($foreground, nm-checkbox-disabled);
  $nm-checkbox-hover-ripple: mat.get-color-from-palette($foreground, nm-checkbox-hover-ripple);
  $nm-checkbox-focus-ripple: mat.get-color-from-palette($foreground, nm-checkbox-focus-ripple);
  $nm-checkbox-press-ripple: mat.get-color-from-palette($foreground, nm-checkbox-press-ripple);
  $nm-checkbox-mark: mat.get-color-from-palette($foreground, nm-checkbox-mark);

  $background: map.get($config, background);
  $nm-checkbox-mark: mat.get-color-from-palette($background, nm-checkbox-mark);

  .mat-checkbox {
    &.cdk-keyboard-focused {
      .mat-checkbox-layout {
        .mat-checkbox-inner-container {
          .mat-ripple {
            .mat-ripple-element {
              opacity: 1 !important;
              background-color: $nm-checkbox-focus-ripple;
            }
          }
        }
      }
    }

    .mat-checkbox-layout {
      width: 24px;
      height: 24px;
      align-items: center;
      justify-content: center;

      .mat-checkbox-inner-container {
        width: 18px;
        height: 18px;

        &:hover {
          .mat-ripple {
            .mat-ripple-element {
              opacity: 1 !important;
              background-color: $nm-checkbox-hover-ripple;
            }
          }
        }

        &:focus-visible {
          outline: none;

          .mat-ripple {
            .mat-ripple-element {
              opacity: 1 !important;
              background-color: $nm-checkbox-focus-ripple;
            }
          }
        }

        &:active {
          .mat-ripple {
            .mat-ripple-element {
              opacity: 1 !important;
              background-color: $nm-checkbox-press-ripple;
            }
          }
        }

        .mat-checkbox-frame {
          border-color: $nm-checkbox;
        }

        .mat-checkbox-background {
          .mat-checkbox-checkmark {
            fill: $nm-checkbox-mark;

            .mat-checkbox-checkmark-path {
              stroke: $nm-checkbox-mark !important;
            }
          }

          .mat-checkbox-mixedmark {
            background-color: $nm-checkbox-mark;
          }
        }
      }
    }

    &.mat-checkbox-checked, &.mat-checkbox-indeterminate {
      .mat-checkbox-layout {
        .mat-checkbox-inner-container {
          .mat-checkbox-background {
            background-color: $nm-checkbox-checked;
            opacity: 1 !important;
            transition: none !important;

            .mat-checkbox-mixedmark {
              transform: none !important;
            }
          }
        }
      }
    }

    &.mat-checkbox-disabled {
      pointer-events: none;

      .mat-checkbox-layout {
        .mat-checkbox-inner-container {
          .mat-checkbox-frame {
            border-color: $nm-checkbox-disabled;
          }
        }
      }

      &.mat-checkbox-checked, &.mat-checkbox-indeterminate {
        .mat-checkbox-layout {
          .mat-checkbox-frame {
            border-color: transparent !important;
          }

          .mat-checkbox-background {
            background-color: $nm-checkbox-disabled !important;
          }
        }
      }
    }
  }
}
