@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  .nm-top-bar.mat-toolbar {
    padding: 24px;
    border-bottom: 1px solid mat.get-color-from-palette($background, nm-top-bar-border);
    background: mat.get-color-from-palette($background, background-layout);
    column-gap: 8px;

    &.with-icon {
      padding: 12px 8px;
    }

    &:not(.transparent) {
      border: none;
      nm-icon-button {
        margin-bottom: -1px;
      }
    }

    &.transparent {
      border: none;
    }

    .controls {
      flex-shrink: 0;
      padding: 0;
      margin-left: auto;

      nm-actions {
        .actions {
          nm-button {
            min-width: fit-content;
          }
        }
      }
    }

    .top-bar-title {
      display: flex;
      min-width: 0;
      color: mat.get-color-from-palette($foreground, nm-top-bar);
      font-size: mat.font-size($typography, nm-top-bar);
      font-weight: mat.font-weight($typography, nm-top-bar);
      letter-spacing: mat.letter-spacing($typography, nm-top-bar);
      line-height: mat.line-height($typography, nm-top-bar);

      .title-content {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .title-count {
        color: mat.get-color-from-palette($foreground, nm-top-bar-count);
        padding-left: 8px;
      }
    }
  }
}
