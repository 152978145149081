@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);
  $typography: mat.get-typography-config($theme);

  .template {
    display: grid;
    grid-auto-flow: row;
    width: 100%;
    overflow: hidden;

    .row {
      display: grid;
      grid-auto-flow: column;
      width: 100%;
      align-items: center;
      height: 100%;
      margin-bottom: 8px;
      border-bottom: 1px solid mat.get-color-from-palette($foreground, nm-table-divider);

      &:first-of-type {
        border-top: 1px solid mat.get-color-from-palette($foreground, nm-table-divider);
      }

      .cell {
        padding: 8px 12px;
      }
    }
  }
}
