@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $typography: mat.get-typography-config($theme);

  $nm-content-card-primary-color: mat.get-color-from-palette($foreground, nm-content-card-primary-color);
  $nm-content-card-secondary-color: mat.get-color-from-palette($foreground, nm-content-card-secondary-color);
  $nm-content-card-link-color: mat.get-color-from-palette($foreground, nm-content-card-link-color);
  $nm-content-card-primary-background: mat.get-color-from-palette($foreground, nm-content-card-primary-background);
  $nm-content-card-secondary-background: mat.get-color-from-palette($foreground, nm-content-card-secondary-background);

  .content-card {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    border: 1px solid $nm-content-card-secondary-background;
    padding: 15px;
    row-gap: 16px;
    background-color: $nm-content-card-primary-background;

    .header {
      display: flex;
      justify-content: space-between;
      column-gap: 12px;

      .info {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        .container {
          display: flex;
          align-items: center;
          column-gap: 4px;
        }

        .content-card-title,
        .description {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }

        .content-card-title {
          color: $nm-content-card-primary-color;
          font-size: mat.font-size($typography, text-md-600);
          font-weight: mat.font-weight($typography, text-md-600);
          letter-spacing: mat.letter-spacing($typography, text-md-600);
          line-height: mat.line-height($typography, text-md-600);
        }

        .description {
          color: $nm-content-card-secondary-color;
          font-size: mat.font-size($typography, text-xs-400);
          font-weight: mat.font-weight($typography, text-xs-400);
          letter-spacing: mat.letter-spacing($typography, text-xs-400);
          line-height: mat.line-height($typography, text-xs-400);
        }
      }

      .actions {
        flex-shrink: 0;
        display: flex;
        justify-content: space-between;
        column-gap: 8px;
      }
    }

    .separator {
      border: none;
      height: 1px;
      background-color: $nm-content-card-secondary-background;
      margin: 0;
    }

    .content {
      .group {
        display: flex;
        flex-direction: column;
        row-gap: 16px;

        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .title,
          .link {
            font-size: mat.font-size($typography, text-2xs-500);
            font-weight: mat.font-weight($typography, text-2xs-500);
            letter-spacing: mat.letter-spacing($typography, text-2xs-500);
            line-height: mat.line-height($typography, text-2xs-500);
          }

          .title {
            color: $nm-content-card-secondary-color;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: inline-block;
          }

          .link {
            color: $nm-content-card-link-color;
          }
        }

        .columns {
          display: grid;
          column-gap: 33px;
          align-items: flex-start;

          .column-item {
            position: relative;
            display: flex;
            height: 100%;

            nm-column-card {
              width: 100%;
            }

            .separator {
              position: absolute;
              right: -16.5px;
              border: none;
              height: calc(100% - 16px);
              width: 1px;
              background-color: $nm-content-card-secondary-background;
              transform: translateY(-50%);
              top: 50%;
            }
          }
        }
      }
    }

    &.mobile {
      .content {
        .group {
          .columns {
            column-gap: 0;
            row-gap: 33px;

            .column-item {
              .separator {
                right: auto;
                height: 1px;
                width: 100%;
                transform: translateY(0);
                top: auto;
                bottom: -16.5px;
              }
            }
          }
        }
      }
    }
  }
}
