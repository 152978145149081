@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);

  $nm-divider: mat.get-color-from-palette($foreground, nm-divider);

  .mat-divider {
    border-top-color: $nm-divider !important;
  }

  .mat-divider-vertical {
    height: 100%;
    border-right-color: $nm-divider !important;
  }
}
