@use '@angular/material' as mat;
@use 'sass:map';

::ng-deep nm-metric {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  $metric-min-width: 140;
  $metric-row-gap: 16;

  .nm-metric-list {
    container: main / inline-size;
    min-width: #{$metric-min-width}px;
    padding-bottom: 16px;

    @for $i from 1 through 10 {
      &.items-count-#{$i} {
        $min-width: $i * $metric-min-width + ($i - 1) * $metric-row-gap;

        @container main (width < #{$min-width}px) {
          .main-container {
            flex-direction: column;
            gap: 8px;
          }
        }
      }
    }

    .main-container {
      display: flex;
      flex-direction: row;
      gap: #{$metric-row-gap}px;
    }
  }
}
