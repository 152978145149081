@use '@angular/material' as mat;
@use 'sass:map';

:host {
  display: flex;
  flex-grow: 1;
  overflow-y: auto;
}

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  .nm-subscription-change {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;

    .body {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(345px, 1fr));
      padding: 12px 32px 0 32px;
      gap: 24px;

      .subscription {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .title-container {
          display: flex;
          flex-direction: column;
          gap: 16px;

          .title-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .title {
              color: mat.get-color-from-palette($foreground, nm-subscription-change-title);
              font-size: mat.font-size($typography, display-xs-600);
              font-weight: mat.font-weight($typography, display-xs-600);
              line-height: mat.line-height($typography, display-xs-600);
              letter-spacing: mat.letter-spacing($typography, display-xs-600);
            }
          }

          .subtitle {
            color: mat.get-color-from-palette($foreground, nm-subscription-change-text);
            font-size: mat.font-size($typography, text-sm-500);
            font-weight: mat.font-weight($typography, text-sm-500);
            letter-spacing: mat.letter-spacing($typography, text-sm-500);
            line-height: mat.line-height($typography, text-sm-500);
          }
        }

        .services {
          display: flex;
          flex-direction: column;
          gap: 8px;
        }

        .section-title {
          color: mat.get-color-from-palette($foreground, nm-subscription-change-title);
          font-size: mat.font-size($typography, text-sm-500);
          font-weight: mat.font-weight($typography, text-sm-500);
          letter-spacing: mat.letter-spacing($typography, text-sm-500);
          line-height: mat.line-height($typography, text-sm-500);
        }

        .section-description {
          min-height: 32px;
          color: mat.get-color-from-palette($foreground, nm-subscription-change-text);
          font-size: mat.font-size($typography, text-xs-400);
          font-weight: mat.font-weight($typography, text-xs-400);
          letter-spacing: mat.letter-spacing($typography, text-xs-400);
          line-height: mat.line-height($typography, text-xs-400);
        }
        .modules-description {
          min-height: min-content;
        }
        .proposal-description {
          text-align: center;
        }
        .accesses {
          display: flex;
          flex-direction: column;
          gap: 8px;
          list-style-type: none;
          padding: 0;
          margin: 0;
          .description-with-icon {
            display: flex;
            align-items: center;
            gap: 4px;
          }
        }

        .modules {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .modules-title {
            color: mat.get-color-from-palette($foreground, nm-subscription-change-title);
            font-size: mat.font-size($typography, text-sm-500);
            font-weight: mat.font-weight($typography, text-sm-500);
            letter-spacing: mat.letter-spacing($typography, text-sm-500);
            line-height: mat.line-height($typography, text-sm-500);
          }

          .module {
            display: flex;
            flex-direction: row;
            gap: 4px;
            align-items: center;

            .module-name {
              color: mat.get-color-from-palette($foreground, nm-subscription-change-text);
              font-size: mat.font-size($typography, text-xs-400);
              font-weight: mat.font-weight($typography, text-xs-400);
              letter-spacing: mat.letter-spacing($typography, text-xs-400);
              line-height: mat.line-height($typography, text-xs-400);
            }

            .module-icon {
              min-width: 16px;

              &.active {
                color: mat.get-color-from-palette($foreground, nm-subscription-change-icon-active);
              }

              &.inactive {
                color: mat.get-color-from-palette($foreground, nm-subscription-change-icon-inactive);
              }
            }
          }
        }

        .activate {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 4px;

          .activate-description {
            text-align: center;
            color: mat.get-color-from-palette($foreground, nm-subscription-change-text);
            font-size: mat.font-size($typography, text-xs-400);
            font-weight: mat.font-weight($typography, text-xs-400);
            letter-spacing: mat.letter-spacing($typography, text-xs-400);
            line-height: mat.line-height($typography, text-xs-400);
          }
          .individual-proposal-link {
            width: 100%;
          }
        }
      }
    }
    .body-medium-screen {
      grid-template-columns: 1fr !important;
    }

    .footer {
      display: flex;
      flex-direction: column;
      gap: 12px;
      max-width: 360px;
      align-self: center;
      padding: 24px 0;

      .footer-title {
        text-align: center;
        color: mat.get-color-from-palette($foreground, nm-subscription-change-text);
        font-size: mat.font-size($typography, text-sm-400);
        font-weight: mat.font-weight($typography, text-sm-400);
        line-height: mat.line-height($typography, text-sm-400);
        letter-spacing: mat.letter-spacing($typography, text-sm-400);
      }

      .phone {
        text-align: center;
        color: mat.get-color-from-palette($foreground, nm-subscription-change-title);
        font-size: mat.font-size($typography, text-md-600);
        font-weight: mat.font-weight($typography, text-md-600);
        line-height: mat.line-height($typography, text-md-600);
        letter-spacing: mat.letter-spacing($typography, text-md-600);
      }

      .contacts {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;
        align-self: center;
      }
    }
  }
}
