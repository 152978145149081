@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);

  $nm-progress-bar: mat.get-color-from-palette($foreground, nm-progress-bar);
  $nm-progress-bar-buffer: mat.get-color-from-palette($foreground, nm-progress-bar-buffer);

  .mat-progress-bar {
    .mat-progress-bar-background {
      fill: transparent;
    }

    .mat-progress-bar-buffer {
      background-color: $nm-progress-bar-buffer;
    }

    .mat-progress-bar-fill {
      &::after {
        background-color: $nm-progress-bar;
      }
    }
  }
}
