@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $typography: mat.get-typography-config($theme);

  .widget-report {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background-color: mat.get-color-from-palette($foreground, nm-widget-background);
    box-shadow: inset 0 0 0 1px mat.get-color-from-palette($foreground, nm-widget-border);
    overflow: hidden;

    .widget-report-content {
      padding: 0 1px 0;
      overflow: hidden;
      border-radius: 0 0 20px 20px;

      ::ng-deep {
        nm-banner-empty-search{
          padding: 16px;
        }
      }
    }
  }
}

.widget-report {
  ::ng-deep {
    nm-banner-empty-search {
      padding: 0 16px 16px;
    }
  }
}
