@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  $nm-tab-label: mat.get-color-from-palette($foreground, nm-tab-label);
  $nm-tab-label-active: mat.get-color-from-palette($foreground, nm-tab-label-active);
  $nm-tab-label-disabled: mat.get-color-from-palette($foreground, nm-tab-label-disabled);
  $nm-tab-ink-bar: mat.get-color-from-palette($foreground, nm-tab-ink-bar);

  $nm-tab-hover: mat.get-color-from-palette($background, nm-tab-hover);
  $nm-tab-focus: mat.get-color-from-palette($background, nm-tab-focus);

  .mat-tab-label-content {
    overflow: hidden;
  }

  .mat-tab-label:not(.something) {
    height: auto;
    line-height: mat.line-height($typography, text-sm-500);

    .mat-tab-label-content {
      width: 100%;
      height: 100%;
    }

    &:not(.mat-tab-disabled) {
      min-width: 0;
      padding: 0;
      color: $nm-tab-label;
      opacity: 1;

      .nm-metric {
        .title, .value, .description {
          color: mat.get-color-from-palette($foreground, nm-tab-metric-not-active);
        }
      }

      &:hover {
        background-color: $nm-tab-hover;
      }

      &:focus {
        background-color: $nm-tab-focus;
      }
    }

    &.mat-tab-label-active {
      color: $nm-tab-label-active;

      .nm-metric {
        .title, .value {
          color: mat.get-color-from-palette($foreground, nm-tab-metric-active);
        }
        .description {
          color: mat.get-color-from-palette($foreground, nm-tab-metric-not-active);
        }
      }
    }

    &.mat-tab-disabled:not(.something) {
      min-width: 0;
      padding: 0;
      color: $nm-tab-label-disabled;
      opacity: 1;

      .nm-metric {
        .title, .value, .description, .secondary-value-container {
          color: mat.get-color-from-palette($foreground, nm-tab-metric-disabled);
        }
      }
    }

    .nm-metric {
      padding: 10px 16px;
      min-width: auto;

      .description {
        text-align: left;
        display: flex;
      }
    }
  }

  .mat-tab-group.mat-primary .mat-ink-bar:not(.something) {
    background: $nm-tab-ink-bar;
  }
}
