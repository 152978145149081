@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);

  $typography: mat.get-typography-config($theme);

  $text-color-selected: mat.get-color-from-palette($background, nm-tab-ink-bar);
  $background-selected: mat.get-color-from-palette($background, nm-drawer-selected);
  $background-title-selected: mat.get-color-from-palette($background, nm-drawer-title-selected);
  $background-hover: mat.get-color-from-palette($background, nm-drawer-hover);
  $foreground-disabled: mat.get-color-from-palette($foreground, disabled);

  .organizations-list {
    width: 228px;
    padding: 8px 0;
  }

  .nav-item-disabled {
    color: $foreground-disabled !important;
  }

  .mat-expansion-panel {
    margin-top: 4px;
  }

  .nm-expansion-panel.mat-expansion-panel {
    border: none;
    background: transparent;
    border-radius: 20px;
    margin-top: 4px;

    .mat-expansion-panel-body {
      padding: 0 0 0 24px;
    }

    &.no-expansion {
      .mat-expansion-panel-body {
        display: none;
      }
    }

    &:not([class*='mat-elevation-z']) {
      box-shadow: none;
    }

    mat-panel-title {
      column-gap: 12px;
    }

    .mat-expansion-panel-header {
      padding: 8px 16px;
      height: 40px !important;
      align-items: inherit;

      .mat-content {
        overflow: visible;
      }

      &:hover {
        &:not([aria-disabled='true']) {
          background: $background-hover;
          border-radius: 24px;
        }
      }

      &.selected {
        background: $background-title-selected;
        border-radius: 24px;
      }

      &.selectedTotal {
        background-color: $background-selected;
        border-radius: 24px;
        .item-title {
          color: mat.get-color-from-palette($foreground, nm-tab-ink-bar);
        }
        .item-icon {
          color: mat.get-color-from-palette($foreground, nm-tab-ink-bar);
        }
      }
    }

    &:not(.mat-expanded) {
      .mat-expansion-panel-header:hover {
        &:not([aria-disabled='true']) {
          background: $background-hover;
          border-radius: 24px;
        }
      }
    }

    .mat-list-base .mat-list-item {
      .mat-list-item-content {
        padding: 8px 16px;
      }
      height: 40px;
      margin-top: 4px;
    }

    .mat-list-item:hover {
      background-color: $background-hover;
      border-radius: 20px;
    }

    .mat-list-item.selected {
      background-color: $background-selected;
      border-radius: 20px;
      .item-title {
        color: mat.get-color-from-palette($foreground, nm-tab-ink-bar);
      }
    }

    &.mat-expansion-panel-spacing {
      margin-bottom: 0;
    }

    ::ng-deep .mat-expansion-panel-body {
      padding: 0 24px;
    }
  }

  .sub-route-title {
    display: flex;
    justify-content: space-between;
    padding: 8px 16px 4px 16px;
    text-transform: uppercase;
    font-size: mat.font-size($typography, text-2xs-500);
    font-weight: mat.font-weight($typography, text-2xs-500);
    letter-spacing: mat.letter-spacing($typography, text-2xs-500);
    line-height: mat.line-height($typography, text-2xs-500);
    color: mat.get-color-from-palette($foreground, nm-drawer-item-icon);
  }

  .nm-navigation-drawer {
    display: flex;
    overflow: hidden;
    width: 256px;
    height: 100%;
    flex-direction: column;
    cursor: pointer;
    border-radius: 0 16px 16px 0;
    background-color: mat.get-color-from-palette($background, background-sidenav);

    .mat-expansion-panel-header-title,
    .item-title {
      overflow: hidden;
      color: mat.get-color-from-palette($foreground, nm-drawer-item);
      font-size: mat.font-size($typography, nm-navigation-drawer);
      font-weight: mat.font-weight($typography, nm-navigation-drawer);
      letter-spacing: mat.letter-spacing($typography, nm-navigation-drawer);
      line-height: mat.line-height($typography, nm-navigation-drawer);
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .mat-accordion {
      .mat-expansion-panel:first-of-type,
      .mat-expansion-panel:last-of-type {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

    .mat-nav-list {
      padding-top: 0;
      padding-left: 12px;
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      height: 64px;
      padding: 8px;
      cursor: default;

      img.logo {
        padding-left: 4px;
      }

      .collapse-btn {
        cursor: pointer;
      }
    }

    .footer,
    .organization {
      display: flex;
      align-items: center;
      padding: 8px;
      border-top: 1px solid mat.get-color-from-palette($background, nm-drawer-border);
      column-gap: 12px;

      .item-icon {
        color: mat.get-color-from-palette($foreground, nm-drawer-item-icon);
        svg {
          margin-right: -24px;
        }
      }

      .profile-arrow {
        min-width: 24px;
        margin-left: auto;
        margin-right: 12px;
      }

      .account-name {
        flex-grow: 0;
      }

      nm-dropdown-menu {
        width: 100%;
      }

      .profile {
        display: flex;
        height: 40px;
        border: none;
        align-items: center;
        padding: 8px 8px 8px 16px;
        column-gap: 12px;

        &.collapsed {
          padding: 8px 16px;
        }

        &:hover {
          background: $background-hover;
          border-radius: 20px;
        }
      }
    }

    .logomark {
      display: none;
    }

    &.mobile {
      .collapse-btn {
        display: none;
      }
    }

    &.collapsed {
      width: 72px;

      .mat-expansion-panel-header {
        padding: 8px 16px;
      }

      .mat-content.mat-content-hide-toggle {
        margin: 0;
      }

      .mat-expansion-panel {
        width: 56px;
      }

      .footer {
        padding: 8px;
      }

      .mat-expansion-panel-header-title {
        margin: 0;
      }

      .header {
        justify-content: center;
      }

      .item-title,
      .item-description,
      .organization-arrow,
      .logo,
      .collapse-btn,
      .profile-arrow {
        display: none;
      }

      .container {
        gap: 0 !important;
        nm-avatar {
          margin-top: 0 !important;
        }
      }

      .content {
        .nm-menu-item {
          &.mat-menu-item {
            padding: 8px 16px;
          }
        }

        .left nm-avatar {
          margin: 0 !important;
        }
        .content-container {
          justify-content: center;
          .text,
          .right {
            display: none;
          }
        }
      }

      .logomark {
        display: block;
      }
    }

    .content {
      flex-grow: 1;
      overflow-y: auto;
      padding: 0 8px;
      overflow-x: hidden;
      scrollbar-width: none;
      flex-direction: column;
      flex-wrap: nowrap;

      .active-organization {
        display: flex;
        border: none;
        align-items: center;
        padding: 8px 8px 8px 16px;
        column-gap: 12px;

        .item-title {
          white-space: normal;
        }

        .item-description {
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: 12px;
          letter-spacing: 0.4px;
          text-transform: uppercase;
        }

        .item-icon {
          color: mat.get-color-from-palette($foreground, nm-drawer-item-icon);
        }

        .container {
          display: flex;
          gap: 12px;
        }

        nm-avatar {
          margin-top: 4px;
        }

        &.collapsed {
          padding: 12px 16px;
        }

        &:hover {
          background: $background-hover;
          border-radius: 24px;
        }
      }

      .organization-arrow {
        min-width: 24px;
        margin-left: auto;
      }

      .nm-menu-item {
        &.mat-menu-item {
          border-radius: 24px;
          padding: 8px 8px 8px 16px;

          .left {
            nm-avatar {
              margin-top: 6px;
            }
          }

          .right {
            align-items: center;
            nm-svg-icon {
              margin: 0;
            }
          }

          .description {
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 12px;
            letter-spacing: 0.4px;
            text-transform: uppercase;
          }
        }
      }

      .organization {
        padding: 0 28px;
        height: 48px;
        border: none;

        &.collapsed {
          padding: 0;
          padding-left: 12px;
        }

        &:hover {
          background: $background-hover;
          border-radius: 24px;
        }
      }

      .nm-menu-content {
        min-width: 40px !important;
      }

      nm-divider {
        margin: 8px;
        display: block;
      }
    }
  }

  .profile-panel {
    padding: 8px;
  }

  .profile-dropdown {
    width: 252px;
    height: 96px;
    font-size: mat.font-size($typography, text-xs-400);
    font-weight: mat.font-weight($typography, text-xs-400);
    letter-spacing: mat.letter-spacing($typography, text-xs-400);
    line-height: mat.line-height($typography, text-xs-400);
    margin-top: 8px;
    margin-bottom: 8px;
    color: mat.get-color-from-palette($foreground, nm-sidnav-profile-text);

    .profile-phone {
      color: mat.get-color-from-palette($background, nm-drag-placeholder);
    }
  }
}
