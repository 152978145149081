@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  nm-menu-content {
    padding: 8px 0;
  }

  nm-table-header-cell-with-menu {
    width: 100%;
    height: 100%;

    .table-header-cell-with-menu {
      width: 100%;
      height: 100%;
      display: flex;
      column-gap: 4px;
      overflow: hidden;
      flex-grow: 0;
      flex-shrink: 0;
      align-items: center;
      justify-content: left;
      color: mat.get-color-from-palette($foreground, nm-table-header-column-text);
      font-size: mat.font-size($typography, nm-table-cell-header);
      font-weight: mat.font-weight($typography, nm-table-cell-header);
      line-height: mat.line-height($typography, nm-table-cell-header);
      cursor: pointer;
      padding: 8px 12px;

      .table-title .name {
        color: mat.get-color-from-palette($foreground, nm-table-header-column-text);
      }

      .column-title {
        overflow: hidden;
      }

      &.select-column {
        justify-content: center;

        .checkbox-wrapper {
          width: 40px;
          height: 40px;
        }
      }

      &.auto-width {
        max-width: 214px;
      }

      nm-svg-icon {
        display: flex;
        align-items: center;
      }
    }

    nm-dropdown-menu {
      width: 100%;
      height: 100%;

      .dropdown-label {
        display: flex;
        height: 100%;
        align-items: center;
      }
    }
  }
  .column-tooltip {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14px;
    height: 14px;
    background-color: mat.get-color-from-palette($background, nm-divider);
    border-radius: 50%;
    color: mat.get-color-from-palette($foreground, nm-autocomplete);
    font-size: 12px;
  }
}
