@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  .nm-sidenav-layout {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 360px;

    mat-sidenav-content.expanded-mobile {
      margin-left: 72px !important;
    }

    mat-sidenav-content.open-sidenav {
      margin-left: 256px;
    }

    mat-sidenav-content.is-mobile {
      margin-left: 0 !important;
    }

    &:not(.mobile) {
      .mat-sidenav-content > .content-wrapper {
        padding: 0 24px;
        border-radius: 0 !important;
      }
    }

    .mat-drawer {
      &.mat-drawer:not(.mat-drawer-side) {
        box-shadow: none;
      }
    }
    .nm-navigation-drawer,
    .mobile {
      box-shadow: mat.get-color-from-palette($background, nm-sidenav-shadow);
    }

    .mat-sidenav-container {
      height: 100%;
      flex-grow: 1;
      background-color: mat.get-color-from-palette($background, background-layout);
    }

    .mat-sidenav-content {
      display: grid;
      grid-auto-flow: column;
      grid-template-areas:
        'header header'
        'content details';
      grid-template-columns: 1fr min-content;
      grid-template-rows: min-content 1fr;

      > .layout-header {
        grid-area: header;
      }

      > .layout-content {
        grid-area: content;
      }

      > .layout-details {
        grid-area: details;
        margin-left: 8px;
      }
    }

    .mat-sidenav-content > .content-wrapper {
      flex-grow: 1;
      padding: 0 16px;
      &::-webkit-scrollbar {
        display: none;
      }

      background-color: mat.get-color-from-palette($background, background-layout);

      overflow-y: auto;

      > router-outlet:empty {
        display: none;
      }

      &.layout-details {
        display: none;
        grid-area: details;
        margin-left: 8px;
      }
    }
    .mat-sidenav-content > .content-wrapper:not(.without-border-radius) {
      border-radius: 16px 16px 0 0;
    }

    &.details {
      .mat-sidenav-content > .content-wrapper.layout-details {
        display: initial;
        width: 400px;
      }
    }

    .mat-drawer-side {
      border: none;
      border-radius: 0 16px 16px 0;
    }
    .table-sidebar {
      background-color: mat.get-color-from-palette($background, nm-sidenav-layout-content) !important;
      border-radius: 16px 0px 0px 16px !important;
      height: 100%;
      box-shadow: 0px 9px 24px 0px rgba(15, 15, 15, 0.06), 0px 3px 6px 0px rgba(15, 15, 15, 0.03), 0px 0px 0px 1px rgba(15, 15, 15, 0.02) !important;
      .mat-drawer-inner-container {
        width: 600px;
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .sidenav-top-bar {
        background-color: mat.get-color-from-palette($background, sidebar-top-bar);
      }
    }
  }
  nm-table-sidebar-layout {
    height: 100%;
  }
  @media screen and (max-width: 762px) {
    .mat-drawer {
      box-shadow: mat.get-color-from-palette($background, nm-sidenav-shadow);
    }
  }
}
