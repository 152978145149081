@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $typography: mat.get-typography-config($theme);

  .filter-label {
    height: 32px;
    width: auto;
    border-radius: 8px;

    border: solid 1px mat.get-color-from-palette($foreground, nm-filter-label-border);
    color: mat.get-color-from-palette($foreground, nm-filter-label);

    padding: 7px 8px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;
    gap: 8px;

    .filter-label-left-side {
      display: flex;
      max-width: 100%;
      gap: 8px;
    }

    .label-text {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      align-self: center;
      font-size: mat.font-size($typography, nm-filter-label);
      font-weight: mat.font-weight($typography, nm-filter-label);
      letter-spacing: mat.letter-spacing($typography, nm-filter-label);
      line-height: mat.line-height($typography, nm-filter-label);
    }

    .icon {
      color: mat.get-color-from-palette($foreground, nm-filter-label-label);
    }

    .right-icon {
      flex: 0;
      text-align: right;
    }

    @media screen and (max-width: 470px) {

        .label-text {
          max-width: 100px;
        }

        .filter-label-left-side {
          max-width: 200px;
        }
    }
  }

}
