@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  .auth-base {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    background-color: mat.get-color-from-palette($background, base-layout-back);

    .header {
      display: flex;
      align-items: center;
      height: 64px;
      padding: 0 8px;

      .top-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .top-bar-title {
          text-align: center;
          flex: 1;
          font-size: mat.font-size($typography, nm-auth-base-title);
          font-weight: mat.font-weight($typography, nm-auth-base-title);
          line-height: mat.line-height($typography, nm-auth-base-title);
          color: mat.get-color-from-palette($foreground, nm-auth-base-title-color);
        }
      }
    }

    .body {
      overflow-y: auto;
      overflow-x: hidden;

      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
    }

    .footer {
      text-align: center;
      box-sizing: content-box;

      font-size: mat.font-size($typography, nm-auth-base-footer);
      font-weight: mat.font-weight($typography, nm-auth-base-footer);
      line-height: mat.line-height($typography, nm-auth-base-footer);
      letter-spacing: mat.letter-spacing($typography, nm-auth-base-footer);
      color: mat.get-color-from-palette($foreground, nm-auth-base-title-color);

      .support-label {
        margin-bottom: 8px;
      }

      .support-phone {
        margin-bottom: 24px;
      }

      .content {
        justify-content: center;
      }
    }

    .page-content {
      margin: 0 auto;
      margin-top: 24px;
    }

    .logo {
      width: 170px;
      height: 40px;
      margin-bottom: 40px;
      margin-left: -10px;
    }

    @media screen and (max-width: 380px) {
      padding-left: 16px;
      padding-right: 16px;

      .page-content {
        width: 300px;
      }
    }
  }
}
