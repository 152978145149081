@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  
  nm-filter-panel {
    width: 100%;
  }

  .filter-panel {
    padding: 8px 0;
    background: mat.get-color-from-palette($foreground, nm-filter-panel-bg);
    border-radius: 4px;
    max-height: 100vh;
    overflow: auto;

    .nm-form-field {
      cursor: pointer;

      .text-wrapper {
        user-select: none;
      }
    }
  }
}
