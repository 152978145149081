@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme ($theme) {
    $config: mat.get-color-config($theme);
    $foreground: map.get($config, foreground);
    $background: map.get($config, background);
    $typography: mat.get-typography-config($theme);

    .auth-base-footer-buttons {
        display: flex;
        justify-content: space-between;

        .prev-icon-button, .next-icon-button {
            display: none;
        }

        .label {
            display: block;
            text-align: center;
            align-self: center;
            width: 33%;

            font-size: mat.font-size($typography, nm-auth-base-label);
            font-weight: mat.font-weight($typography, nm-auth-base-label);
            line-height: mat.line-height($typography, nm-auth-base-label);

            color: mat.get-color-from-palette($foreground, nm-auth-base-label-color);
        }

        .left-buttons {
            width: 33%;
            text-align: left;
        }

        .right-buttons {
            display: flex;
            width: 33%;
            justify-content: flex-end;

            .skip-button {
                margin-right: 16px;
                width: 126px;
            }

            .next-button {
                width: 133px;
            }
        }

        @media screen and (max-width: 600px) {
            .label {
                display: none;
            }

            .left-buttons {
                width: 50%;
            }

            .right-buttons {
                width: 50%;
            }
        }

        @media screen and (max-width: 960px) {
            .left-buttons {
                width: 20%;
            }

            .label {
                width: 30%;
                text-align: right;
            }

            .right-buttons {
                width: 50%;
            }
        }

        @media screen and (max-width: 400px) {
            .prev-button, .next-button {
                display: none;
            }
            .prev-icon-button, .next-icon-button {
                display: block;
            }
        }
    }
}
