@use '@angular/material' as mat;
@use 'sass:map';

:host {
  width: 100%;
}

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);
  $typography: mat.get-typography-config($theme);

  .nm-control-label {
    display: flex;
    color: mat.get-color-from-palette($foreground, nm-control-label);
    column-gap: 4px;
    font-size: mat.font-size($typography, nm-control-label);
    font-weight: mat.font-weight($typography, nm-control-label);
    letter-spacing: mat.letter-spacing($typography, nm-control-label);
    line-height: mat.line-height($typography, nm-control-label);

    .label-counter {
      margin-left: auto;
      color: mat.get-color-from-palette($foreground, nm-label-counter);
      font-size: mat.font-size($typography, nm-label-counter);
      font-weight: mat.font-weight($typography, nm-label-counter);
      letter-spacing: mat.letter-spacing($typography, nm-label-counter);
      line-height: mat.line-height($typography, nm-label-counter);
    }

    .label-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.required::before {
      color: mat.get-color-from-palette($foreground, nm-label-required);
      content: '*';
    }
  }
}
