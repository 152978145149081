@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);

  $nm-switch: mat.get-color-from-palette($foreground, nm-switch);
  $nm-switch-bg: mat.get-color-from-palette($foreground, nm-switch-bg);
  $nm-switch-checked: mat.get-color-from-palette($foreground, nm-switch-checked);
  $nm-switch-checked-mark: mat.get-color-from-palette($foreground, nm-switch-checked-mark);
  $nm-switch-checked-action-mark: mat.get-color-from-palette($foreground, nm-switch-checked-action-mark);
  $nm-switch-checked-icon: mat.get-color-from-palette($foreground, nm-switch-checked-icon);
  $nm-switch-disabled: mat.get-color-from-palette($foreground, nm-switch-disabled);
  $nm-switch-disabled-bg: mat.get-color-from-palette($foreground, nm-switch-disabled-bg);
  $nm-switch-disabled-mark: mat.get-color-from-palette($foreground, nm-switch-disabled-mark);

  .nm-switch {
    position: relative;
    display: block;
    overflow: hidden;
    width: 52px;
    height: 32px;
    border-width: 2px;
    border-style: solid;
    border-color: $nm-switch;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    background-color: $nm-switch-bg;
    border-radius: 35px;
    cursor: pointer;
    user-select: none;

    .nm-switch__container {
      display: flex;
      height: 100%;
      align-items: center;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      transform: translateX(6px);
    }

    .nm-switch__toggle {
      position: relative;
      display: flex;
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      background-color: $nm-switch;
      border-radius: 100px;
      transition: background-color 0.15s ease-in-out;
    }

    .nm-switch__icon {
      position: absolute;
      animation-duration: 1s;
      animation-fill-mode: forwards;

      &--close {
        color: $nm-switch-bg;
      }

      &--done {
        color: $nm-switch-checked-icon;
        opacity: 0;
        visibility: hidden;
      }
    }

    .nm-switch__stub {
      position: absolute;
      top: -2px;
      left: -2px;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      background-color: transparent;
      cursor: not-allowed;
    }

    &--disabled,
    &:disabled {
      border-color: $nm-switch-disabled;
      background-color: $nm-switch-disabled-bg;
      pointer-events: none;

      .nm-switch__container {
        .nm-switch__toggle {
          background-color: $nm-switch-disabled-mark;
        }
      }
    }

    &.nm-switch--icon {
      .nm-switch__container {
        transform: translateX(2px);

        .nm-switch__toggle {
          width: 24px;
          height: 24px;
        }
      }
    }

    &.nm-switch--active {
      border-color: $nm-switch-checked;
      background-color: $nm-switch-checked;

      &:not(.nm-switch--icon) {
        .nm-switch__container {
          transform: translateX(26px);

          .nm-switch__toggle {
            background-color: $nm-switch-checked-mark;
            transform: scale(1.5);
          }
        }
      }

      &.nm-switch--icon {
        .nm-switch__container {
          transform: translateX(22px);

          .nm-switch__toggle {
            background-color: $nm-switch-checked-mark;

            .nm-switch__icon--close {
              opacity: 0;
              visibility: hidden;
            }

            .nm-switch__icon--done {
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }

      &:hover {
        .nm-switch__container {
          .nm-switch__toggle {
            background-color: $nm-switch-checked-action-mark !important;
          }
        }
      }

      &.nm-switch--disabled,
      &.nm-switch:disabled {
        border-color: transparent;
        background-color: $nm-switch-disabled;
      }
    }

    &.nm-switch--on {
      animation-name: switch-on;

      &:not(.nm-switch--icon) {
        .nm-switch__container {
          animation-name: switch-on-container;

          .nm-switch__toggle {
            animation-name: switch-on-toggle;
          }
        }
      }

      &.nm-switch--icon {
        .nm-switch__container {
          animation-name: switch-icon-on-container;

          .nm-switch__toggle {
            animation-name: switch-icon-on-toggle;

            .nm-switch__icon--close {
              animation-name: switch-icon-on-close;
            }

            .nm-switch__icon--done {
              animation-name: switch-icon-on-done;
            }
          }
        }
      }
    }

    &.nm-switch--off {
      animation-name: switch-off;

      &:not(.nm-switch--icon) {
        .nm-switch__container {
          animation-name: switch-off-container;

          .nm-switch__toggle {
            animation-name: switch-off-toggle;
          }
        }
      }

      &.nm-switch--icon {
        .nm-switch__container {
          animation-name: switch-icon-off-container;

          .nm-switch__toggle {
            animation-name: switch-icon-off-toggle;

            .nm-switch__icon--close {
              animation-name: switch-icon-off-close;
            }

            .nm-switch__icon--done {
              animation-name: switch-icon-off-done;
            }
          }
        }
      }
    }
  }

  @keyframes switch-on {
    0% {
      border-color: $nm-switch;
      background-color: $nm-switch-bg;
      pointer-events: none;
    }

    45% {
      border-color: $nm-switch;
      background-color: $nm-switch-bg;
      pointer-events: none;
    }

    50% {
      border-color: $nm-switch-checked;
      background-color: $nm-switch-checked;
      pointer-events: none;
    }

    100% {
      border-color: $nm-switch-checked;
      background-color: $nm-switch-checked;
      pointer-events: auto;
    }
  }

  @keyframes switch-on-container {
    0% {
      transform: translateX(6px);
    }

    25% {
      transform: translateX(6px);
    }

    50% {
      transform: translateX(26px);
    }

    100% {
      transform: translateX(26px);
    }
  }

  @keyframes switch-icon-on-container {
    0% {
      transform: translateX(2px);
    }

    25% {
      transform: translateX(2px);
    }

    50% {
      transform: translateX(22px);
    }

    100% {
      transform: translateX(22px);
    }
  }

  @keyframes switch-on-toggle {
    0% {
      background-color: $nm-switch;
      transform: scale(1);
    }

    25% {
      background-color: $nm-switch;
      transform: scale(1.75);
    }

    45% {
      background-color: $nm-switch-checked-mark;
    }

    50% {
      background-color: $nm-switch-checked-mark;
      transform: scale(1.75);
    }

    75% {
      background-color: $nm-switch-checked-mark;
      transform: scale(1.75);
    }

    100% {
      background-color: $nm-switch-checked-mark;
      transform: scale(1.5);
    }
  }

  @keyframes switch-icon-on-toggle {
    0% {
      background-color: $nm-switch;
      transform: scale(1);
    }

    25% {
      background-color: $nm-switch;
      transform: scale(1.1666);
    }

    45% {
      background-color: $nm-switch-checked-mark;
    }

    50% {
      background-color: $nm-switch-checked-mark;
      transform: scale(1.1666);
    }

    75% {
      background-color: $nm-switch-checked-mark;
      transform: scale(1.1666);
    }

    100% {
      background-color: $nm-switch-checked-mark;
      transform: scale(1);
    }
  }

  @keyframes switch-off {
    0% {
      border-color: $nm-switch-checked;
      background-color: $nm-switch-checked;
      pointer-events: none;
    }

    45% {
      border-color: $nm-switch-checked;
      background-color: $nm-switch-checked;
      pointer-events: none;
    }

    50% {
      border-color: $nm-switch;
      background-color: $nm-switch-bg;
      pointer-events: none;
    }

    100% {
      border-color: $nm-switch;
      background-color: $nm-switch-bg;
      pointer-events: auto;
    }
  }

  @keyframes switch-off-container {
    0% {
      transform: translateX(26px);
    }

    25% {
      transform: translateX(26px);
    }

    50% {
      transform: translateX(6px);
    }

    100% {
      transform: translateX(6px);
    }
  }

  @keyframes switch-icon-off-container {
    0% {
      transform: translateX(22px);
    }

    25% {
      transform: translateX(22px);
    }

    50% {
      transform: translateX(2px);
    }

    100% {
      transform: translateX(2px);
    }
  }

  @keyframes switch-off-toggle {
    0% {
      background-color: $nm-switch-checked-mark;
      transform: scale(1.5);
    }

    25% {
      background-color: $nm-switch-checked-mark;
      transform: scale(1.75);
    }

    45% {
      background-color: $nm-switch;
    }

    50% {
      background-color: $nm-switch;
      transform: scale(1.75);
    }

    75% {
      background-color: $nm-switch;
      transform: scale(1.75);
    }

    100% {
      background-color: $nm-switch;
      transform: scale(1);
    }
  }

  @keyframes switch-icon-off-toggle {
    0% {
      background-color: $nm-switch-checked-mark;
      transform: scale(1);
    }

    25% {
      background-color: $nm-switch-checked-mark;
      transform: scale(1.1666);
    }

    45% {
      background-color: $nm-switch;
    }

    50% {
      background-color: $nm-switch;
      transform: scale(1.1666);
    }

    75% {
      background-color: $nm-switch;
      transform: scale(1.1666);
    }

    100% {
      background-color: $nm-switch;
      transform: scale(1);
    }
  }

  @keyframes switch-icon-on-close {
    0% {
      opacity: 1;
      visibility: visible;
    }

    20% {
      opacity: 0;
      visibility: hidden;
    }

    100% {
      opacity: 0;
      visibility: hidden;
    }
  }

  @keyframes switch-icon-on-done {
    0% {
      opacity: 0;
      visibility: hidden;
    }

    40% {
      opacity: 0;
      visibility: hidden;
    }

    100% {
      opacity: 1;
      visibility: visible;
    }
  }

  @keyframes switch-icon-off-close {
    0% {
      opacity: 0;
      visibility: hidden;
    }

    40% {
      opacity: 0;
      visibility: hidden;
    }

    100% {
      opacity: 1;
      visibility: visible;
    }
  }

  @keyframes switch-icon-off-done {
    0% {
      opacity: 1;
      visibility: visible;
    }

    20% {
      opacity: 0;
      visibility: hidden;
    }

    100% {
      opacity: 0;
      visibility: hidden;
    }
  }
}

:host {
  position: relative;
  display: inline-block;
  line-height: 0;
}
