$media-max-xs: '(max-width: 480px)';
$media-xs: '(min-width: 480px)';
$media-s: '(min-width: 768px)';
$media-m: '(min-width: 1024px)';
$media-l: '(min-width: 1440px)';
$media-1200-deprecated: '(min-width: 1200px)';
$media-1100-deprecated: '(min-width: 1100px)';

@mixin media-mixin ($media) {
  @media only screen and #{$media} {
    @content;
  }
}
