@use '@angular/material' as mat;
@use 'sass:map';

:host {
  display: inline-block;
  line-height: 0;
}

::ng-deep {
  .mat-radio-button {
    .mat-radio-label {
      .mat-radio-label-content {
        display: none;
      }
    }
  }
}

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);

  $nm-radio: mat.get-color-from-palette($foreground, nm-radio);
  $nm-radio-checked: mat.get-color-from-palette($foreground, nm-radio-checked);
  $nm-radio-disabled: mat.get-color-from-palette($foreground, nm-radio-disabled);
  $nm-radio-hover-ripple: mat.get-color-from-palette($foreground, nm-radio-hover-ripple);
  $nm-radio-hover-ripple: mat.get-color-from-palette($foreground, nm-radio-hover-ripple);
  $nm-radio-focus-ripple: mat.get-color-from-palette($foreground, nm-radio-focus-ripple);
  $nm-radio-press-ripple: mat.get-color-from-palette($foreground, nm-radio-press-ripple);

  .mat-radio-button {
    &.cdk-keyboard-focused {
      .mat-radio-label {
        .mat-radio-container {
          .mat-ripple {
            .mat-ripple-element {
              opacity: 1;
              background-color: $nm-radio-focus-ripple;
            }
          }
        }
      }
    }

    .mat-radio-label {
      width: 24px;
      height: 24px;
      justify-content: center;

      .mat-radio-container {
        &:hover {
          .mat-ripple {
            .mat-ripple-element {
              opacity: 1;
              background-color: $nm-radio-hover-ripple;
            }
          }
        }

        &:focus-visible {
          outline: none;

          .mat-ripple {
            .mat-ripple-element {
              opacity: 1;
              background-color: $nm-radio-focus-ripple;
            }
          }
        }

        &:active {
          .mat-ripple {
            .mat-ripple-element {
              opacity: 1;
              background-color: $nm-radio-press-ripple;
            }
          }
        }

        .mat-radio-outer-circle {
          border-color: $nm-radio;
        }
      }
    }

    &.mat-radio-checked {
      .mat-radio-label {
        .mat-radio-container {
          .mat-radio-outer-circle {
            border-color: $nm-radio-checked;
          }

          .mat-radio-inner-circle {
            background-color: $nm-radio-checked;
          }
        }
      }
    }

    &.mat-radio-disabled {
      pointer-events: none;

      .mat-radio-label {
        .mat-radio-container {
          .mat-radio-outer-circle {
            border-color: $nm-radio-disabled;
          }
        }
      }

      &.mat-radio-checked {
        .mat-radio-label {
          .mat-radio-inner-circle {
            background-color: $nm-radio-disabled !important;
          }
        }
      }
    }
  }
}
