@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);

  $nm-progress-spinner: mat.get-color-from-palette($foreground, nm-progress-spinner);

  .mat-progress-spinner {
    svg {
      circle {
        stroke: $nm-progress-spinner;
      }
    }
  }
}
