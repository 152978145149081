@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);

  .control-panel {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    width: 100%;
    padding: 12px 16px;
    border-radius: 16px;
    background: mat.get-color-from-palette($background, nm-control-panel-background);
  }

  .header, .content {
    display: flex;
    flex-wrap: wrap;
  }

  .left-side {
    flex: 1;

    .filters-list {
      display: grid;
      grid-template-columns: auto 60px;
      grid-gap: 10px;
    }

    nm-input-search, [filter], nm-filter-period-new, .settings {
      grid-column: 1 / 3;

      &:first-child {
        grid-column: 1 / 1;
      }
    }

    .filters-icon {
        display: none;
    }

    @media (max-width: 768px) {

      .filters-icon {
        display: flex;
        grid-column-start: 2;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 1;
        min-width: 60px;
        align-self: center;
        cursor: pointer;
      }
    }
  }

  .right-side {
    display: grid;
    grid-column-gap: 8px;
  }

  @media (max-width: 767px) {
    nm-input-search {
      align-items: center;
      display: flex;
      height: 40px;
    }
  }
}
