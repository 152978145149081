@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  .nm-list-item {
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 8px 0;

    .main-container {
      display: flex;
      flex-grow: 1;
      container: main / inline-size;
      min-width: 136px;

      .main-content {
        display: flex;
        flex-grow: 1;
        width: 100%;
        column-gap: 8px;
        row-gap: 2px;
        padding-top: 2px;

        .label-container {
          display: flex;
          flex-grow: 1;
          flex-basis: 30%;
          flex-direction: column;
          min-width: 64px;
          user-select: none;

          .label {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            word-wrap: break-word;
            font-size: mat.font-size($typography, text-sm-400);
            font-weight: mat.font-weight($typography, text-sm-400);
            letter-spacing: mat.letter-spacing($typography, text-sm-400);
            line-height: mat.line-height($typography, text-sm-400);
            color: mat.get-color-from-palette($foreground, nm-list-item-label);
          }

          .label-secondary {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            word-wrap: break-word;
            font-size: mat.font-size($typography, text-xs-400);
            font-weight: mat.font-weight($typography, text-xs-400);
            letter-spacing: mat.letter-spacing($typography, text-xs-400);
            line-height: mat.line-height($typography, text-xs-400);
            color: mat.get-color-from-palette($foreground, nm-list-item-label-secondary);
          }
        }

        .progress-container {
          display: flex;
          flex-grow: 1;
          flex-basis: 30%;
          flex-direction: row;
          gap: 8px;
          min-width: 64px;
          user-select: none;

          .progress-bar-empty {
            height: 4px;
            width: 100%;
            margin: 8px 0;
            border-radius: 2px;
            background-color: mat.get-color-from-palette($foreground, nm-list-item-progress-bar-empty);

            .progress-bar-filled {
              height: 4px;
              border-radius: 2px;
              background-color: mat.get-color-from-palette($foreground, nm-list-item-progress-bar-filled);
            }

            .progress-bar-green {
              height: 4px;
              border-radius: 2px;
              background-color: mat.get-color-from-palette($foreground, nm-list-item-progress-bar-green);
            }
          }

          .progress-value {
            font-size: mat.font-size($typography, text-sm-400);
            font-weight: mat.font-weight($typography, text-sm-400);
            letter-spacing: mat.letter-spacing($typography, text-sm-400);
            line-height: mat.line-height($typography, text-sm-400);
            color: mat.get-color-from-palette($foreground, nm-list-item-progress-value);
          }
        }

        .value-container {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          flex-basis: 30%;
          min-width: 64px;
          text-align: end;
          user-select: none;

          .value {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            word-wrap: break-word;
            font-size: mat.font-size($typography, text-sm-400);
            font-weight: mat.font-weight($typography, text-sm-400);
            letter-spacing: mat.letter-spacing($typography, text-sm-400);
            line-height: mat.line-height($typography, text-sm-400);
            color: mat.get-color-from-palette($foreground, nm-list-item-value);
            position: relative;
            margin-right: -10px;
            padding-right: 10px;
          }

          .value-secondary {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            word-wrap: break-word;
            font-size: mat.font-size($typography, text-xs-400);
            font-weight: mat.font-weight($typography, text-xs-400);
            letter-spacing: mat.letter-spacing($typography, text-xs-400);
            line-height: mat.line-height($typography, text-xs-400);
            margin-right: -10px;
            padding-right: 10px;

            &.plain {
              color: mat.get-color-from-palette($foreground, nm-list-item-value-secondary-plain);
            }

            &.error {
              color: mat.get-color-from-palette($foreground, nm-list-item-value-secondary-error);
            }

            &.success {
              color: mat.get-color-from-palette($foreground, nm-list-item-value-secondary-success);
            }
          }
        }
      }

      @container main (max-width: 599px) {
        .main-content:not(.something) {
          flex-wrap: wrap;

          .label-container, .value-container  {
            flex-basis: 40%;
          }

          .progress-container {
            flex-basis: 100%;
            order: 3;
          }
        }
      }
    }

    .index {
      padding-top: 2px;
      padding-left: 12px;
      user-select: none;
      font-size: mat.font-size($typography, text-sm-400);
      font-weight: mat.font-weight($typography, text-sm-400);
      letter-spacing: mat.letter-spacing($typography, text-sm-400);
      line-height: mat.line-height($typography, text-sm-400);
      color: mat.get-color-from-palette($foreground, nm-list-item-index);
    }

    .icon-left {
      color: mat.get-color-from-palette($foreground, nm-list-item-left-icon);
      min-width: 24px;
    }

    .icon-arrow {
      cursor: pointer;
      color: mat.get-color-from-palette($foreground, nm-list-item-arrow);
      min-width: 24px;
    }
  }
}
