@use '@angular/material' as mat;
@use 'sass:map';

.nm-input-file {
  display: flex;
  flex-direction: column;
  gap: 4px;
  container: input-file / inline-size;

  .nm-input-file-dropzone {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 12px;
    border-width: 1px;
    border-style: dashed;
    border-radius: 8px;
    gap: 12px;
    min-width: fit-content;

    .preview {
      display: flex;
      justify-content: center;
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
      gap: 16px;
      text-align: center;
    }

    .button {
      display: flex;
      flex-grow: 1;
    }

    .buttons {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 8px;
    }

    .file-input {
      display: none;
    }
  }
}

@container input-file (min-width: 550px) {
  .nm-input-file {
    .nm-input-file-dropzone {
      flex-direction: row !important;

      .right {
        text-align: initial !important;
      }
    }
  }
}

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  $nm-input-file-background-dragover: mat.get-color-from-palette($background, nm-input-file-dragover);

  $nm-input-file-description: mat.get-color-from-palette($foreground, nm-input-file-description);
  $nm-input-file-description-disabled: mat.get-color-from-palette($foreground, nm-input-file-description-disabled);
  $nm-input-file-border: mat.get-color-from-palette($foreground, nm-input-file-border);
  $nm-input-file-border-disabled: mat.get-color-from-palette($foreground, nm-input-file-border-disabled);
  $nm-input-file-border-dragover: mat.get-color-from-palette($foreground, nm-input-file-border-dragover);

  .nm-input-file {
    .nm-input-file-dropzone {
      border-color: $nm-input-file-border;
      color: $nm-input-file-description;
      font-size: mat.font-size($typography, text-sm-400);
      font-weight: mat.font-weight($typography, text-sm-400);
      letter-spacing: mat.letter-spacing($typography, text-sm-400);
      line-height: mat.line-height($typography, text-sm-400);

      &.nm-input-file-dropzone-dragover {
        border-color: $nm-input-file-border-dragover;
        background-color: $nm-input-file-background-dragover;
      }

      &.nm-input-file-dropzone-disabled {
        border-color: $nm-input-file-border-disabled;
        color: $nm-input-file-description-disabled;
      }
    }
  }
}
