@use '@angular/material' as mat;
@use 'sass:map';

:host {
  display: block;
}

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  $nm-menu-item: mat.get-color-from-palette($background, nm-menu-item);
  $nm-menu-item-divider: mat.get-color-from-palette($foreground, nm-menu-item-divider);
  $nm-menu-search-empty: mat.get-color-from-palette($foreground, nm-menu-search-empty);

  .nm-menu-content {
    .divider {
      border-top: 1px solid $nm-menu-item-divider;
    }

    .search {
      .empty {
        padding: 14px 0;
        color: $nm-menu-search-empty;
        font-size: mat.font-size($typography, text-sm-500);
        font-weight: mat.font-weight($typography, text-sm-500);
        letter-spacing: mat.letter-spacing($typography, text-sm-500);
        line-height: mat.line-height($typography, text-sm-500);
      }
    }
  }

  .nm-menu-content {
    .search {
      display: flex;
      min-width: 260px;
      flex-direction: column;
      padding: 0 12px 0;
      row-gap: 8px;
    }

    .controls {
      display: flex;
      justify-content: right;
      padding: 24px 12px 16px;
      gap: 10px;
    }

    .content {
      width: 100%;
      .create-menu-item {
        width: 100%;
      }
    }

    .divider {
      margin: 8px 0 7px;
    }

    .loading {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px;
    }

    .items-container {
      overflow: auto;
    }
  }

  .mat-menu-panel {
    &:not(.something) {
      max-width: 320px;
      background-color: $nm-menu-item;
      box-shadow: mat.get-color-from-palette($foreground, nm-filter-panel-shadow);
    }
  }
}
