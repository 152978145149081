@use '@angular/material' as mat;
@use 'sass:map';

:host {
  .nm-banner {
    width: fit-content;
    border-radius: 28px;

    .wrapper {
      display: flex;
      min-width: 288px;
      max-width: 600px;
      margin: auto;
    }

    .content {
      display: flex;
      gap: 12px;
    }

    .icon-wrapper {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
    }

    .title {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .container {
      display: flex;
      overflow: hidden;
      flex-direction: column;
      gap: 8px;
    }

    .actions {
      display: flex;
      align-items: center;
      padding-top: 8px;
      gap: 10px;
    }

    .picture {
      flex-shrink: 0;
      padding-left: 12px;
      margin: auto;
    }

    .image {
      width: 140px;
      height: 140px;
      object-fit: contain;
    }

    .close {
      flex-shrink: 0;
      margin-top: -8px;
    }

    &.layout-desktop {
      .wrapper {
        flex-direction: row;
        padding: 24px;
      }
    }

    &.layout-mobile {
      .wrapper {
        flex-direction: column-reverse;
        padding: 16px;
      }

      .content {
        flex-direction: column;
        padding: 8px;
      }

      .icon-wrapper {
        order: -2;
      }

      .actions {
        flex-direction: column;
      }

      .picture {
        order: -1;
        padding-left: 0;
      }

      .close {
        align-self: flex-end;
        margin-top: 0;
      }
    }

    &.fix {
      width: auto;
    }

    &.center {
      .title {
        text-align: center;
      }
    }
  }
}

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  $nm-banner-background: mat.get-color-from-palette($background, nm-banner-background);
  $nm-banner-title: mat.get-color-from-palette($foreground, nm-banner-title);
  $nm-banner-body: mat.get-color-from-palette($foreground, nm-banner-body);

  .nm-banner {
    background-color: $nm-banner-background;

    .title {
      color: $nm-banner-title;
      font-size: mat.font-size($typography, text-md-600);
      font-weight: mat.font-weight($typography, text-md-600);
      letter-spacing: mat.letter-spacing($typography, text-md-600);
      line-height: mat.line-height($typography, text-md-600);
    }

    .body, .header {
      color: $nm-banner-body;
      font-size: mat.font-size($typography, text-sm-400);
      font-weight: mat.font-weight($typography, text-sm-400);
      letter-spacing: mat.letter-spacing($typography, text-sm-400);
      line-height: mat.line-height($typography, text-sm-400);
    }
  }
}
