@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  $nm-menu-header-label: mat.get-color-from-palette($foreground, nm-menu-header-label);
  $nm-menu-header-description: mat.get-color-from-palette($foreground, nm-menu-header-description);
  $nm-menu-header-icon: mat.get-color-from-palette($foreground, nm-menu-header-icon);

  .nm-menu-header {
    display: flex;
    justify-content: space-between;

    padding: 0 4px;
    height: 48px;

    .content-container {
      overflow: hidden;
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
      gap: 12px;
    }

    .label {
      color: $nm-menu-header-label;
      font-size: mat.font-size($typography, text-sm-500);
      font-weight: mat.font-weight($typography, text-sm-500);
      line-height: mat.line-height($typography, text-sm-500);
      letter-spacing: mat.letter-spacing($typography, text-sm-500);
    }

    .description {
      overflow: hidden;
      text-overflow: ellipsis;
      color: $nm-menu-header-description;
      font-size: mat.font-size($typography, text-xs-400);
      font-weight: mat.font-weight($typography, text-xs-400);
      letter-spacing: mat.letter-spacing($typography, text-xs-400);
      line-height: mat.line-height($typography, text-xs-400);
    }


    .left {
      display: flex;
      align-items: center;
      overflow: hidden;
      flex-direction: row;
      justify-content: flex-start;
      white-space: initial;
      .left-button {
        display: flex;
        gap: 8px;
      }
    }

    nm-svg-icon {
      color: $nm-menu-header-icon;
      min-width: 24px;
    }

    &.nm-menu-header-description {
      .left > :not(.text) {
        margin-top: 8px;
      }

      .right > * {
        margin-top: 8px;
      }
    }

    .text {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      padding: 2px 0 2px 8px;

      &.multiline {
        .label, .description {
          white-space: pre-line;
        }
      }
    }
  }
}
