@use '@angular/material' as mat;
@use 'sass:map';

.nm-input-image {
  position: relative;
  display: flex;
  width: 124px;
  height: 92px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 22px 8px;
  border-radius: 4px;
  gap: 8px;

  &.nm-input-image-preview {
    padding: 0;
  }

  .label {
    overflow: hidden;
    width: 100%;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .image {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    object-fit: cover;
  }

  .control-icon {
    position: absolute;
    top: 4px;
    right: 4px;
    display: none;
    padding: 8px;
    border-radius: 20px;
    cursor: pointer;
  }

  &:hover {
    .control-icon {
      display: flex;
    }
  }

  .spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  }
}

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  $nm-input-image-background: mat.get-color-from-palette($background, nm-input-image);
  $nm-input-image-background-disabled: mat.get-color-from-palette($background, nm-input-image-disabled);
  $nm-input-image-background-trash-icon: mat.get-color-from-palette($background, nm-input-image-trash-icon);

  $nm-input-image-foreground: mat.get-color-from-palette($foreground, nm-input-image);
  $nm-input-image-foreground-disabled: mat.get-color-from-palette($foreground, nm-input-image-disabled);
  $nm-input-image-foreground-file-icon: mat.get-color-from-palette($foreground, nm-input-image-file-icon);
  $nm-input-image-foreground-trash-icon: mat.get-color-from-palette($foreground, nm-input-image-trash-icon);

  .cover {
    position: absolute;
    bottom: 4px;
    left: 4px;
    text-transform: uppercase;
    color: mat.get-color-from-palette($foreground, nm-input-image-cover);
    font-size: mat.font-size($typography, text-2xs-500);
    font-weight: mat.font-weight($typography, text-2xs-500);
    letter-spacing: mat.letter-spacing($typography, text-2xs-500);
    line-height: mat.line-height($typography, text-2xs-500);
  }

  .nm-input-image {
    background-color: $nm-input-image-background;
    color: $nm-input-image-foreground;
    font-size: mat.font-size($typography, text-xs-400);
    font-weight: mat.font-weight($typography, text-xs-400);
    letter-spacing: mat.letter-spacing($typography, text-xs-400);
    line-height: mat.line-height($typography, text-xs-400);

    .mat-icon {
      color: $nm-input-image-foreground;
    }


    &.nm-input-image-disabled {
      background-color: $nm-input-image-background-disabled;
      color: $nm-input-image-foreground-disabled;

      .mat-icon {
        color: $nm-input-image-foreground-disabled;
      }

      .image {
        opacity: 0.38;
      }
    }

    .file-icon {
      color: $nm-input-image-foreground-file-icon;
    }

    .trash-icon {
      background-color: $nm-input-image-background-trash-icon;
      color: $nm-input-image-foreground-trash-icon;
    }
  }
}
