@use '@angular/material' as mat;
@use 'sass:map';
@import 'media-vars';

::ng-deep nm-custom-table {
  height: 100%;
}

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);
  $typography: mat.get-typography-config($theme);

  .custom-table {
    overflow: hidden;
    position: relative;
    height: 100%;
  }

  .table-wrapper {
    height: 100%;
    padding: 0 16px;
    margin: 0 -16px;
    overflow-y: auto;
    scrollbar-width: none;

    &.table-for-widget-table {
      overflow-y: hidden;
      overflow-x: auto;
    }
  }

  .table {
    display: table;
    width: 100%;

    .empty-title {
      width: 100%;
      height: 52px;
      display: flex;
      justify-content: center;
      align-items: center;

      font-size: mat.font-size($typography, text-sm-400);
      font-weight: mat.font-weight($typography, text-sm-400);
      letter-spacing: mat.letter-spacing($typography, text-sm-400);
      line-height: mat.line-height($typography, text-sm-400);
      color: mat.get-color-from-palette($background, nm-table-empty-title);
    }

    .table-header {
      .checkbox-wrapper {
        display: none;
      }
      .number {
        display: flex;
      }

      &:hover {
        .checkbox-wrapper {
          display: flex;
        }
        .number {
          display: none;
        }
      }
    }

    &.with-selected {
      nm-table-header {
        .checkbox-wrapper {
          display: flex;
        }
        .number {
          display: none;
        }
      }

      .row {
        .column {
          .checkbox-wrapper {
            display: flex !important;
          }
          .number-wrapper {
            display: none !important;
          }
        }
      }
    }

    .row {
      .column {
        &.select-column {
          .checkbox-wrapper {
            display: none;
          }
          .number-wrapper {
            display: flex;
          }
        }
      }

      &:not(.mobile-row):hover {
        .column {
          &.select-column {
            .checkbox-wrapper {
              display: flex;
            }
            .number-wrapper {
              display: none;
            }
          }
        }
      }
    }

    &.with-selected {
    }

    .loading {
      height: 123px;
    }

    .load-up {
      height: 48px;
    }
  }
}
