@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  .organization-item {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;

    nm-svg-icon {
      color: mat.get-color-from-palette($foreground, nm-organization-item-icon);
    }

    .name {
      display: flex;
      align-items: center;
      width: 100%;

      .item-icon {
        margin-right: 12px;
      }

      .label {
        .account-name {
          font-size: mat.font-size($typography, text-sm-600);
          font-weight: mat.font-weight($typography, text-sm-600);
          line-height: mat.line-height($typography, text-sm-600);
          letter-spacing: mat.letter-spacing($typography, text-sm-600);
          color: mat.get-color-from-palette($foreground, nm-organization-item-name);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 492px;
        }

        .description {
          font-size: mat.font-size($typography, text-xs-400);
          font-weight: mat.font-weight($typography, text-xs-400);
          line-height: mat.line-height($typography, text-xs-400);
          letter-spacing: mat.letter-spacing($typography, text-xs-400);
          color: mat.get-color-from-palette($foreground, nm-organization-item-description);
        }
      }
    }

    @media screen and (max-width: 600px) {
      .name {

        .label {

          .account-name {
            width: 292px;
          }
        }
      }
    }

    @media screen and (max-width: 340px) {
      .name {

        .label {

          .account-name {
            width: 155px;
          }
        }
      }
    }
  }
}
