@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);

    .dropdown-menu-panel {
        background: mat.get-color-from-palette($foreground, nm-filter-panel-bg);
        box-shadow: mat.get-color-from-palette($foreground, nm-filter-panel-shadow);
        border-radius: 4px;
        max-height: 100vh;
        overflow: auto;
    }

    .overflow-panel {
      .nm-menu-content {
        .items-container {
          max-height: none !important;
          overflow: visible;
        }
      }
    }
}