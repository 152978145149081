@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);
  $typography: mat.get-typography-config($theme);

  .total-row {
    position: relative;
    display: flex;

    .hide-expand{
      display: none;
    }

    .inner {
      display: grid;
      grid-auto-flow: column;
      width: 100%;
      align-items: center;
    }

    .column {
      display: flex;
      overflow: hidden;
      word-break: break-word;

      min-width: 64px;
      min-height: 36px;
      height: 100%;
      flex-grow: 0;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      color: mat.get-color-from-palette($foreground, nm-table-column-text);
      font-size: mat.font-size($typography, nm-table-cell);
      font-weight: mat.font-weight($typography, nm-table-cell);
      line-height: mat.line-height($typography, nm-table-cell);
      letter-spacing: mat.letter-spacing($typography, nm-table-cell);
      padding: 8px 12px;
      border-bottom: 1px solid mat.get-color-from-palette($foreground, nm-table-divider);

      &.grouped-row {
        background-color: mat.get-color-from-palette($foreground, nm-table-grouped-row);

        .col-content {
          font-weight: mat.font-weight($typography, nm-table-grouped-cell);
        }
      }

      .column-content {
        display: flex;
        width: 100%;
        color: mat.get-color-from-palette($foreground, nm-table-column-text);
        font-size: mat.font-size($typography, nm-table-cell);
        font-weight: mat.font-weight($typography, nm-table-cell);
        line-height: mat.line-height($typography, nm-table-cell);
        letter-spacing: mat.letter-spacing($typography, nm-table-cell);

        .total-column-data {
          display: flex;
          flex-direction: column;

          .col-content {
            font-weight: mat.font-weight($typography, nm-table-cell-header);
            font-size: mat.font-size($typography, nm-table-cell-header);
            line-height: mat.line-height($typography, nm-table-cell-header);
            letter-spacing: mat.letter-spacing($typography, nm-table-cell-header);
          }

          .sub-content {
            color: mat.get-color-from-palette($foreground, nm-table-total-sub-row);
            font-weight: mat.font-weight($typography, nm-table-sub-total-cell);
            font-size: mat.font-size($typography, nm-table-sub-total-cell);
            line-height: mat.line-height($typography, nm-table-sub-total-cell);
            letter-spacing: mat.letter-spacing($typography, nm-table-sub-total-cell);
          }
        }
      }
    }
  }
}
