@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $typography: mat.get-typography-config($theme);

  .mat-calendar-period-button {
    color: mat.get-color-from-palette($foreground, nm-icon-button-text) !important;
  }

  .back {
    display: flex;
    align-items: center;
    height: 48px;
    color: mat.get-color-from-palette($foreground, nm-filter-header-color);
    font-size: mat.font-size($typography, nm-filter-header);
    font-weight: mat.font-weight($typography, nm-filter-header);
    letter-spacing: mat.letter-spacing($typography, nm-filter-header);
    line-height: mat.line-height($typography, nm-filter-header);
    padding: 8px 0;
    margin-top: 8px !important;

    nm-icon-button {
      margin-right: 8px;
    }
  }

  .filter-period-with-mode {
    position: relative;
    cursor: pointer;

    ::ng-deep {
      .nm-form-field {
        .text-wrapper {
          position: static !important;
          user-select: none;
        }
      }
    }

    .field {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      visibility: hidden;
    }
  }

  .calendar-panel {
    width: 512px;

    .calendar-panel-body {
      display: flex;
    }

    .calendar,
    .presets {
      width: 256px;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .presets {
      margin-top: 14px;
      padding: 8px 16px;

      .preset-selector {
        display: block;
      }
    }
  }

  .mat-calendar {
    width: 100%;
  }

  .controls {
    display: flex;
    padding: 24px 12px 16px;
    justify-content: flex-end;

    nm-button {
      min-width: 113px;
    }

    .cancel {
      margin-right: 10px;
    }
  }

  .checkbox {
    display: flex;
    align-items: center;
    gap: 7px;
    font-size: 14px;
    color: mat.get-color-from-palette($foreground, nm-checkbox);
  }
}

