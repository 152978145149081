@use '@angular/material' as mat;
@use 'sass:map';

highcharts-chart {
  display: block;
  width: 100%;
}

@mixin nm-graph ($config, $typography) {
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);

  .highcharts-background {
    fill: transparent;
  }

  .highcharts-scrolling {
    height: auto !important;
    max-height: 480px;
  }

  .highcharts-scrollable-mask {
    fill: mat.get-color-from-palette($background,nm-graph-background) !important;
    fill-opacity: 0.5;
  }

  .highcharts-legend-item, .highcharts-axis-labels {
    text, span {
      color: mat.get-color-from-palette($foreground, nm-graph-legend) !important;
      cursor: auto !important;
      fill: mat.get-color-from-palette($foreground, nm-graph-legend) !important;
      font-family: mat.font-family($typography, nm-graph-tooltip) !important;
      font-size: mat.font-size($typography, nm-graph-text) !important;
      font-weight: mat.font-weight($typography, nm-graph-text) !important;
      letter-spacing: mat.letter-spacing($typography, nm-graph-text) !important;
      line-height: mat.line-height($typography, nm-graph-text) !important;
    }

    span {
      width: max-content;
      white-space: normal !important;
    }
  }

  .highcharts-data-labels, .highcharts-stack-labels {
    text {
      font-family: mat.font-family($typography, nm-graph-tooltip) !important;
      font-size: mat.font-size($typography, nm-graph-labels) !important;
      font-weight: mat.font-weight($typography, nm-graph-labels) !important;
      letter-spacing: mat.letter-spacing($typography, nm-graph-labels) !important;
      line-height: mat.line-height($typography, nm-graph-labels) !important;
      fill: mat.get-color-from-palette($foreground, nm-graph-legend) !important;
      color: mat.get-color-from-palette($foreground, nm-graph-legend) !important;

      .highcharts-text-outline {
        stroke-width: 0;
        fill: none;
      }
    }
  }

  .highcharts-legend-item.highcharts-column-series > span {
    top: 7px !important;
  }

  .no-data {
    color: mat.get-color-from-palette($foreground, nm-graph-legend);
    font-size: mat.font-size($typography, nm-graph-text);
    font-weight: mat.font-weight($typography, nm-graph-text);
    letter-spacing: mat.letter-spacing($typography, nm-graph-text);
    line-height: mat.line-height($typography, nm-graph-text);
  }

  .highcharts-axis-line, .highcharts-grid-line, .highcharts-point {
    stroke: mat.get-color-from-palette($background, nm-graph-axis);
  }
}

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);

  .nm-graph-tooltip {
    padding: 4px 8px;

    .tooltip-marker-text {
      display: flex;
      align-items: center;
      column-gap: 4px;
    }

    .tooltip-marker {
      width: 12px;
      height: 12px;
      border: 1px solid mat.get-color-from-palette($background, nm-graph-tooltip-marker-border);
    }

    .highcharts-tooltip-box {
      fill: mat.get-color-from-palette($background, nm-graph-tooltip);
    }

    text, span {
      color: mat.get-color-from-palette($foreground, nm-graph-tooltip) !important;
      fill: mat.get-color-from-palette($foreground, nm-graph-tooltip) !important;
      font-family: mat.font-family($typography, nm-graph-tooltip) !important;
      font-size: mat.font-size($typography, nm-graph-tooltip) !important;
      font-weight: mat.font-weight($typography, nm-graph-tooltip) !important;
      letter-spacing: mat.letter-spacing($typography, nm-graph-tooltip) !important;

      tspan {
        font-family: mat.font-family($typography, nm-graph-tooltip) !important;
        font-size: mat.font-size($typography, nm-graph-tooltip) !important;
        font-weight: mat.font-weight($typography, nm-graph-tooltip) !important;
        letter-spacing: mat.letter-spacing($typography, nm-graph-tooltip) !important;
        line-height: mat.line-height($typography, nm-graph-tooltip) !important;
      }
    }
  }

  .nm-graph {
    @include nm-graph($config, $typography);

    .nm-graph-wrapper {
      display: block;
    }
  }
}
