@use '@angular/material' as mat;
@use 'sass:map';

@import 'typography';

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $typography: mat.get-typography-config($theme);

  $nm-micro-banner-information: mat.get-color-from-palette($foreground, nm-micro-banner-information);
  $nm-micro-banner-information-color: mat.get-color-from-palette($foreground, nm-micro-banner-information-color);
  $nm-micro-banner-success: mat.get-color-from-palette($foreground, nm-micro-banner-success);
  $nm-micro-banner-success-color: mat.get-color-from-palette($foreground, nm-micro-banner-success-color);
  $nm-micro-banner-warning: mat.get-color-from-palette($foreground, nm-micro-banner-warning);
  $nm-micro-banner-warning-color: mat.get-color-from-palette($foreground, nm-micro-banner-warning-color);
  $nm-micro-banner-error: mat.get-color-from-palette($foreground, nm-micro-banner-error);
  $nm-micro-banner-error-color: mat.get-color-from-palette($foreground, nm-micro-banner-error-color);

  .micro-banner {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 240px;
    padding: 8px;
    border-radius: 8px;

    &.information {
      background-color: $nm-micro-banner-information;

      .title, .description, .link {
        color: $nm-micro-banner-information-color;
      }
    }

    &.success {
      background-color: $nm-micro-banner-success;

      .title, .description, .link {
        color: $nm-micro-banner-success-color;
      }
    }

    &.warning {
      background-color: $nm-micro-banner-warning;

      .title, .description, .link {
        color: $nm-micro-banner-warning-color;
      }
    }

    &.error {
      background-color: $nm-micro-banner-error;

      .title, .description, .link {
        color: $nm-micro-banner-error-color;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      padding: 8px;
    }

    .title {
      font-size: mat.font-size($typography, text-md-600);
      font-weight: mat.font-weight($typography, text-md-600);
      letter-spacing: mat.letter-spacing($typography, text-md-600);
      line-height: mat.line-height($typography, text-md-600);
    }

    .description, .link {
      font-size: mat.font-size($typography, text-sm-400);
      font-weight: mat.font-weight($typography, text-sm-400);
      letter-spacing: mat.letter-spacing($typography, text-sm-400);
      line-height: mat.line-height($typography, text-sm-400);
    }

    .link {
      text-decoration: underline;
      white-space: nowrap;
    }
  }
}
